import React from 'react';

import { useNavigate } from 'react-router';
import { Button, Col } from 'antd';

import DynamicIcon from '../helpers/DynamicIcon';
import { getLabel } from '../helpers/Translations';

const path = window.location.pathname;

function AppMenuButton(props) {

  const classes = props.classes;
  const name = props.name;
  const icon = props.icon
  const route = props.value;
  const appId = props.appId;

  const navigate = useNavigate();

  function onClick(){
    navigate("/" + route + "/" + appId, "_self");
  }

  return (
    <Col span={12} xs={{span:12}} sm={{span:8}} md={{span:6}} xl={{span:6}} xxl={{span:6}} >
      <Button onClick={onClick} type='primary' className={'ea-menu-button flex column w-100 fs-18 align-center '+classes}>
        <div className='fs-40'>
         <DynamicIcon icon={icon}/>
        </div>
        <div className='mt-10'>
          {getLabel(name)}
        </div>
      </Button>
    </Col>
  );
}

export default AppMenuButton;