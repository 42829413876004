import { useState } from 'react';

import { Layout, Drawer, message } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { getLabel } from '../helpers/Translations';
import { UserData } from '../helpers/UserData';
import { Context } from '../helpers/Context';
import DashboardMenu from './DashboardMenu';
import LogoExtended from './LogoExtended';

const { Header, Content, Sider } = Layout;

const currentLang = localStorage.getItem("__lang");

function DashboardLayout({children}){

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  

  //funzione che mostra il drawer in mobile
  const showDrawer = () => {
    document.querySelector("body").classList.toggle("vw-100");
    setOpen(true);
  };

  //funzione per chiudere il drawer in mobile
  const onClose = () => {
    document.querySelector("body").classList.toggle("vw-100");
    setOpen(false);
  };

  //funzione per minimizzare il menu laterale da desktop
  const onCollapse = (value) => {
    localStorage.setItem("__ea_sidebar_collapsed", value)
    setCollapsed(value)
    document.getElementById("layoutContent").classList.toggle("expand-content")
    document.getElementById("siderLogoFont").classList.toggle("opacity-0")
  };

  return (
      <Layout
        className='ea-dashboard'
          style={{
            minHeight: '100vh',
          }}
        > 
      
          {/* header e drawer per mobile */}
          <section className='ea-header mobile-show flex space-between align-center'>
            <div className='ea-hedaer-left'>
              <MenuOutlined onClick={showDrawer} className="ea-show-drawer mobile-show"/>
            </div>        
            <div className='ea-hedaer-center'>
            </div>        
            <div className='ea-hedaer-right'>
              <div className='ea-logo-wrapper'>
                <LogoExtended/>
              </div>
            </div>        
          </section>
          <Drawer className="ea-mobile-menu" placement="left" width={"260px"} onClose={onClose} open={open}>
            <DashboardMenu/>
          </Drawer>
          {/* drawer per mobile */}
      
          {/* sidebar desktop */}
          <Sider 
            theme='light'
            className='ea-sidebar mobile-hide'
            collapsible 
            onCollapse={(value) => onCollapse(value)}
          >    
            <div className='ea-logo-wrapper flex w-100 p-10'>
              <img
                className='ea-logo-extended'
                src='/ea-icon-colored.svg'
              />
              <img
                id='siderLogoFont'
                className="ea-logo-extended transition-01"
                src='/ea-font-colored.svg'
              />
            </div>
            <DashboardMenu/>
          </Sider>
        
          {/* sidebar desktop */}
      
          {/* conent del layout dinamico */}
          <Content 
            id='layoutContent'
          >
              {children}
          </Content>
          {/* conent del layout dinamico */}
      
      </Layout>
  )
}

export default DashboardLayout;