const languages = {
  //etichette generiche
  titolo:{
    "it":"Titolo",
    "en":"Title"
  },
  modifica:{
    "it":"Modifica",
    "en":"Edit"
  },
  sottotitolo:{
    "it":"Sottotiolo",
    "en":"Subtitle"
  },
  copertina:{
    "it":"Copertina",
    "en":"Cover"
  },
  generale:{
    "it":"Generale",
    "en":"General"
  },
  immagine:{
    "it":"Immagine",
    "en":"Image"
  },
  verticale:{
    "it":"verticale",
    "en":"vertical"
  },
  orizzontale:{
    "it":"orizzontale",
    "en":"horizontal"
  },
  salva:{
    "it":"Salva",
    "en":"Save"
  },
  salva_rimani:{
    "it":"Salva e Rimani",
    "en":"Save and Stay"
  },
  identita:{
    "it":"Brand Identity",
    "en":"Brand Identity"
  },
  icona:{
    "it":"Icona",
    "en":"Icon"
  },
  per:{
    "it":"per",
    "en":"for"
  },
  nuova:{
    "it":"Nuova",
    "en":"New"
  },
  nuovo:{
    "it":"Nuovo",
    "en":"New"
  },
  colori:{
    "it":"Colori",
    "en":"colors"
  },
  colore_principale:{
    "it":"Colore Principale",
    "en":"Primary Color"
  },
  colore_secondario:{
    "it":"Colore Secondario",
    "en":"Secondary Color"
  },
  colore_secondario:{
    "it":"Colore Secondario",
    "en":"Secondary Color"
  },
  colore_sfondo:{
    "it":"Colore di Sfondo",
    "en":"Background Color"
  },
  colore_testo:{
    "it":"Colore del testo",
    "en":"Text color"
  },
  colore_bottoni:{
    "it":"Colore dei bottoni",
    "en":"Buttons color"
  },
  colore_contenuto_bottoni:{
    "it":"Colore contenuto bottoni",
    "en":"Text button color"
  },
  colore_testo_barra:{
    "it":"Colore testo barra superiore",
    "en":"Text navbar color"
  },
  immagine_sfondo_bottoni:{
    "it":"Immagine sfondo bottoni e toolbar",
    "en":"Background image of buttons and toolbar"
  },
  lingua:{
    "it":"Lingua",
    "en":"Languages"
  },
  carica:{
    "it":"Carica",
    "en":"Upload"
  },
  caricamento:{
    "it":"Caricamento...",
    "en":"Loading..."
  },
  copia:{
    "it":"Copia",
    "en":"Copy"
  },
  incolla:{
    "it":"Incolla",
    "en":"Paste"
  },
  nome:{
    "it":"Nome",
    "en":"Name"
  },
  email:{
    "it":"Email",
    "en":"Email"
  },
  strumenti:{
    "it":"Strumenti",
    "en":"Tools"
  },
  azioni:{
    "it":"Azioni",
    "en":"Actions"
  },
  yes:{
    "it":"Si",
    "en":"Yes"
  },
  tema:{
    "it":"Tema",
    "en":"Theme"
  },
  gruppo:{
    "it":"Gruppo",
    "en":"Group"
  },
  impostazioni:{
    "it":"Impostazioni",
    "en":"Settings"
  },
  generici:{
    "it":"Generici",
    "en":"General"
  },
  duplica:{
    "it":"Duplica",
    "en":"Duplicate"
  },
  statistiche:{
    "it":"Statistiche",
    "en":"Insights"
  },
  elementi_mostrati:{
    "it":"Elementi mostrati su",
    "en":"Displayed results on"
  },
  pagina:{
    "it":"Pagina",
    "en":"Page"
  },
  seleziona_lingua:{
    "it":"Seleziona Lingua",
    "en":"Select Language"
  },
  filtra:{
    "it":"Filtra",
    "en":"Filter"
  },
  reset_filtri:{
    "it":"Reset Filtri",
    "en":"Reset Filter"
  },
  campo_obbligatorio:{
    "it":"Questo campo è obbilgatorio",
    "en":"This field is required"
  },

  //inputs
  app_multipla:{
    "it":"App Multipla",
    "en":"App Franchise"
  },
  send:{
    "it":"Invia",
    "en":"Send"
  },
  socials:{
    "it":"Canali Social",
    "en":"Socials"
  },
  google_login:{
    "it":"Login con Google",
    "en":"Google login"
  },
  scarica_contenuti:{
    "it":"Scarica contenuti su richiesta",
    "en":"Download on demand"
  },
  manuale:{
    "it":"Manuale",
    "en":"Manual"
  },
  attivazione_manuale:{
    "it":"Attivazione Manuale",
    "en":"Manual Activation"
  },
  vincola_dispositivi:{
    "it":"Vincola Dispositivi",
    "en":"Restrict Devices"
  },
  scaduta:{
    "it":"Scaduta",
    "en":"Expired"
  },
  position:{
    "it":"Posizione",
    "en":"Position"
  },
  nascondi:{
    "it":"Nascondi",
    "en":"Hide"
  },
  crypt:{
    "it":"Criptaggio",
    "en":"Crypting"
  },
  port:{
    "it":"Porta",
    "en":"Port"
  },
  type:{
    "it":"Tipo",
    "en":"Type"
  },
  receiver:{
    "it":"Destinatario",
    "en":"Receiver"
  },
  sender:{
    "it":"Mittente",
    "en":"Sender"
  },
  invalid:{
    "it":"Invalido",
    "en":"Not Valid"
  },
  titolo_pagina_placeholder:{
    "it":"Il titolo darà il nome al pulsante e alla pagina",
    "en":"The title will name the button and the page"
  },
  sottotitolo_pagina_placeholder:{
    "it":"Il sottotitolo comparirà prima del corpo della pagina",
    "en":"The subtitle will appear before the body of the page"
  },
  prodotti_massimi_ordine_set:{
    "it":"Prodotti Massimi Per Ordine Set",
    "en":"Max products for SetList"
  },
  nascondi_prezzi_ospiti:{
    "it":"Nascondi prezzi agli ospiti",
    "en":"Hide price to guest users"
  },
  moduli_aggiuntivi:{
    "it":"Moduli Aggiuntivi",
    "en":"Extra Forms"
  },
  modulo_registrazione:{
    "it":"Modulo Registrazione",
    "en":"SignIn Form"
  },
  modulo_prodotto:{
    "it":"Modulo Prodotto",
    "en":"Product Form"
  },
  modulo_ordine:{
    "it":"Modulo Ordine",
    "en":"Check-Out Form"
  },
  consenti_pagamento_parziale:{
    "it":"Consenti Pagamento Parziale",
    "en":"Consent partial payment"
  },
  usa_coupons_master:{
    "it":"Usa Coupons app master",
    "en":"Use AppMaster Coupons"
  },
  url_notification:{
    "it":"URL di Notifica Nuovo ordine",
    "en":"Url notification new order"
  },
  transazione_acquisto:{
    "it":"Transazione all'Acquisto",
    "en":"Loyalty Transaction at order"
  },
  riferimento:{
    "it":"Riferimento",
    "en":"Reference"
  },
  carrello:{
    "it":"Carrello",
    "en":"Cart"
  },
  minimo:{
    "it":"Minimo",
    "en":"Minimum"
  },
  billing:{
    "it":"Fatturazione",
    "en":"Billing"
  },
  cf:{
    "it":"Codice Fiscale",
    "en":"Fiscal Code"
  },
  lotteria:{
    "it":"Codice Lotteria",
    "en":"Lottery Code"
  },
  telefono:{
    "it":"Telefono",
    "en":"Phone"
  },
  cellulare:{
    "it":"Cellulare",
    "en":"Mobile"
  },
  termini:{
    "it":"Termini e Condizioni",
    "en":"Terms and Conditions"
  },
  opzioni:{
    "it":"Opzioni",
    "en":"Options"
  },
  attivazione_clienti:{
    "it":"Attivazione clienti",
    "en":"Customers activation"
  },
  prodotti_massimi_catalogo:{
    "it":"Prodotti massimi in catalogo",
    "en":"Max Products catalogue"
  },
  nascondi_campi_registrazione:{
    "it":"Nascondi i campi registrazione",
    "en":"Hide Sign In fields"
  },
  attiva_con_sms:{
    "it":"Attiva clienti con SMS",
    "en":"Enable customers by SMS"
  },
  importo:{
    "it":"Importo",
    "en":"Amount"
  },
  cliente:{
    "it":"Cliente",
    "en":"Customer"
  },
  promotore:{
    "it":"Promotore",
    "en":"Promoter"
  },
  invitato:{
    "it":"Invitato",
    "en":"Invited"
  },

  //dati personali
  profilo:{
    "it":"Profilo",
    "en":"Profile"
  },
  dati_personali:{
    "it":"Dati Personali",
    "en":"Personal Data"
  },
  modifica_password:{
    "it":"Modifica Password",
    "en":"Edit Password"
  },
  username:{
    "it":"Nome Utente",
    "en":"Username"
  },
  cognome:{
    "it":"Cognome",
    "en":"Surname"
  },
  genere:{
    "it":"Genere",
    "en":"Gender"
  },
  data_nascita:{
    "it":"Data di nascita",
    "en":"Birthday"
  },

  //filtri
  sort_by:{
    "it":"Ordina per",
    "en":"Sort by"
  },
  order_by:{
    "it":"Ordina per",
    "en":"Sort by"
  },
  group_id:{
    "it":"Gruppo di pagine",
    "en":"Group pages"
  },
  logged:{
    "it":"Clienti autenticati",
    "en":"Logged users"
  },
  not_logged:{
    "it":"Clienti non autenticati",
    "en":"Not logged users"
  },
  in_home:{
    "it":"In Home",
    "en":"In Home"
  },
  in_menu:{
    "it":"In Menu",
    "en":"In Menu"
  },
  data_creazione:{
    "it":"Data di creazione",
    "en":"Created at"
  },
  citta:{
    "it":"Città",
    "en":"City"
  },
  indirizzo:{
    "it":"Indirizzo",
    "en":"Address"
  },
  ragione_sociale:{
    "it":"Ragione Sociale",
    "en":"Company Name"
  },
  cap:{
    "it":"Codice Postale",
    "en":"Zip Code"
  },
  enabled:{
    "it":"Abilitata",
    "en":"Enabled"
  },
  status:{
    "it":"Stato",
    "en":"Status"
  },
  disabled:{
    "it":"Disabilitata",
    "en":"Disabled"
  },
  is_published:{
    "it":"Pubblicata",
    "en":"Published"
  },
  published:{
    "it":"Pubblicata",
    "en":"Published"
  },
  unpublished:{
    "it":"Non Pubblicata",
    "en":"Unpublished"
  },
  //configura
  apps_pages:{
    "it":"Pagine",
    "en":"Pages"
  },
  apps_locations:{
    "it":"Sedi",
    "en":"Locations"
  },
  apps_galleries:{
    "it":"Fotogallery",
    "en":"Photogallery"
  },
  apps_forms:{
    "it":"Moduli",
    "en":"Forms"
  },
  apps_emails:{
    "it":"Emails",
    "en":"Emails"
  },
  apps_quizzes:{
    "it":"Quiz",
    "en":"Quiz"
  },
  apps_waiting_queues_settings:{
    "it":"Liste D'attesa",
    "en":"Waiting Queues"
  },
  apps_beacons:{
    "it":"Beacons",
    "en":"Beacons"
  },
  apps_marketplaces:{
    "it":"Marketplaces",
    "en":"Marketplaces"
  },
  apps_homes:{
    "it":"Home",
    "en":"Home"
  },

  //strumenti
  loyalty_pos:{
    "it":"POS",
    "en":"POS"
  },
  campagna:{
    "it":"Campagna",
    "en":"Campaign"
  },
  apps_devices_notifications:{
    "it":"Notifiche",
    "en":"Notifications"
  },
  attivita:{
    "it":"Attività",
    "en":"Activity"
  },
  apps_customers:{
    "it":"Clienti",
    "en":"Customers"
  },
  loyalty_transactions:{
    "it":"Transazioni",
    "en":"Transactions"
  },
  shops_coupons:{
    "it":"Coupons",
    "en":"Coupons"
  },
  loyalty_programs:{
    "it":"Campagne",
    "en":"Loyalty Programs"
  },
  apps_inboxes:{
    "it":"Posta",
    "en":"Inobxes"
  },
  shops_products:{
    "it":"Prodotti",
    "en":"Products"
  },
  shops_orders:{
    "it":"Ordini",
    "en":"Orders"
  },
  apps_calendars:{
    "it":"Calendario",
    "en":"Calendar"
  },
  apps_customers_waiting_queues:{
    "it":"Prenotazioni",
    "en":"Bookings"
  },

  //pagine
  tooltip_title:{
    "it":"Il titolo comparirà anche sul bottone in homepage, si consiglia di non superare i 10 caratteri",
    "en":"The title will also appear on the button on the homepage, it is advisable not to exceed 10 characters"
  },
  tooltip_subtitle:{
    "it":"Il sottotitolo comparirà all'interno della pagina sotto la copertina",
    "en":"The subtitle will appear within the page under the cover"
  },
  tooltip_as_background:{
    "it":"Attivando questa spunta, la foto di copertina verrà utilizzata anche come sfondo del pulsante in homepage",
    "en":"By activating this check, the cover photo will also be used as the background of the button on the homepage"
  },
  tooltip_gruppo:{
    "it":"Selezionando il gruppo farai in modo che questa pagina uscirà in quel gruppo inserito in un'altra pagina come widget",
    "en":"This option will display this page in another group of pages selected as widget of another page (nesting page function)"
  },
  tooltip_contenuti_app:{
    "it":"Scegli se questa pagina deve ospitare contenuti dal widget di una sua sotto-app",
    "en":"Choose if this page should host content from its sub-app widget"
  },
  come_sfondo_bottone:{
    "it":"Come sfondo bottone",
    "en":"As button backgorund"
  },
  usa_come_sfondo_bottone:{
    "it":"Usa come sfondo bottone",
    "en":"Use as button backgorund"
  },
  corpo:{
    "it":"Corpo",
    "en":"Body"
  },
  gruppo_appartenenza:{
    "it":"Gruppo di appartenenza",
    "en":"Belonging group"
  },
  carica_da_pc:{
    "it":"Carica dal pc",
    "en":"Upload from pc"
  },
  sfoglia_dalla_raccolta:{
    "it":"Sfoglia dalla raccolta",
    "en":"Choose from library",
  },
  widget:{
    "it":"Widget",
    "en":"Widget",
  },
  app_contents:{
    "it":"Contenuti App",
    "en":"App Contents",
  },
  nessun_widget:{
    "it":"Nessun widget selezionato",
    "en":"No widget selected",
  },
  cerca_seleziona:{
    "it":"Cerca o Seleziona",
    "en":"Search or Select an option",
  },
  contenuti_app:{
    "it":"Contenuti App",
    "en":"App Contents",
  },
  visualizzazione:{
    "it":"Regole di visibilità",
    "en":"Display rules",
  },
  visibile:{
    "it":"Visibile",
    "en":"Display",
  },
  gruppi_pagine:{
    "it":"Gruppi di pagine",
    "en":"Page groups",
  },
  gruppi_clienti:{
    "it":"Gruppi di clienti",
    "en":"Customers groups",
  },

  //widgets
  widget_refer_friend:{
    "it":"Porta un Amico",
    "en":"Refer Friend"
  },
  gallery:{
    "it":"Gallerie",
    "en":"Fotogallery"
  },
  calendar:{
    "it":"Moduli Calendario",
    "en":"Calendar Modules"
  },
  catalogo:{
    "it":"Categorie Catalogo",
    "en":"Catalogue Categories"
  },
  shop:{
    "it":"Categorie dello Shop",
    "en":"Shop's Categories"
  },
  form:{
    "it":"Moduli",
    "en":"Forms"
  },
  album:{
    "it":"Gruppi di Gallerie",
    "en":"Albums"
  },
  location:{
    "it":"Sedi",
    "en":"Locations"
  },
  pages:{
    "it":"Gruppi di Pagine",
    "en":"Pages groups"
  },
  queue:{
    "it":"Liste di Attesa",
    "en":"Queue's Lists"
  },
  quiz:{
    "it":"Quiz",
    "en":"Quiz"
  },
  quiz:{
    "it":"Quiz",
    "en":"Quiz"
  },

  //traduzione nomi widget dal valore
  sedi:{
    "it":"Sedi",
    "en":"Locations"
  },
  contatti:{
    "it":"Contatti",
    "en":"Contacts"
  },
  notifiche:{
    "it":"Notifiche",
    "en":"Notifications"
  },
  facebook:{
    "it":"Facebook",
    "en":"Facebook"
  },
  wordpress:{
    "it":"Wordpress",
    "en":"Wordpress"
  },
  search:{
    "it":"Cerca",
    "en":"Search"
  },
  quizzes_summary:{
    "it":"Quiz",
    "en":"Quiz"
  },
  catalogo:{
    "it":"Catalogo",
    "en":"Catalogue"
  },
  shop:{
    "it":"E-Commerce",
    "en":"E-Commerce"
  },
  vertical_shop:{
    "it":"E-Commerce Verticale",
    "en":"Vertical E-Commerce"
  },
  shopbusiness:{
    "it":"E-Commerce Business",
    "en":"E-Commerce Business"
  },
  categoriesshop:{
    "it":"E-Commerce Categorie",
    "en":"E-Commerce Categories"
  },
  cart:{
    "it":"Carrello",
    "en":"Cart"
  },
  ordersbusiness:{
    "it":"Ordini Business",
    "en":"Orders Business"
  },
  ordini:{
    "it":"Ordini",
    "en":"Orders"
  },
  fidelity:{
    "it":"Fidelity",
    "en":"Fidelity"
  },
  fidelity_plus:{
    "it":"Fidelity Plus",
    "en":"Fidelity Plus"
  },
  coupon:{
    "it":"Coupons",
    "en":"Coupons"
  },
  refer_friend:{
    "it":"Porta un amico",
    "en":"Refer Friend"
  },
  set_list:{
    "it":"Lista Set",
    "en":"Set List"
  },
  set_list_shop:{
    "it":"Prodotti Lista Set",
    "en":"Products Set List"
  },
  set_list_orders:{
    "it":"Ordini Lista Set",
    "en":"Set List Orders"
  },
  accedi:{
    "it":"Accedi",
    "en":"Login"
  },
  registrati:{
    "it":"Registrati",
    "en":"Sign In"
  },
  profilo:{
    "it":"Profilo",
    "en":"Profile"
  },
  account:{
    "it":"Account",
    "en":"Account"
  },
  noleggio:{
    "it":"Noleggio",
    "en":"Rental"
  },
  rental_cart:{
    "it":"Carrello Noleggio",
    "en":"Rental Cart"
  },
  orders_rental:{
    "it":"Ordini Noleggio",
    "en":"Rental Orders"
  },

};

const myLang = localStorage.getItem("__lang");

function getLabel(word){
  if(!languages[word]) return word;
  return languages[word][myLang]
};


export {languages, getLabel, myLang};