import React, { useState } from 'react';

import { PieChartOutlined, UserOutlined, MobileOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Menu } from 'antd';

import { getLabel, myLang } from '../helpers/Translations';
import { UserData } from '../helpers/UserData';
import { ItFlag, EnFlag } from './Flags';

//costruzione del singolo elemento di menù
function getItem(label, key, icon, className, children) {
  return {
    key,
    icon,
    children,
    label,
    className
  };
}

//elementi del menu e del menu secondario
const items = [
  getItem(getLabel("statistiche"), 'insights', <PieChartOutlined />),
  getItem('Apps', 'apps', <MobileOutlined />),
  getItem(UserData.name + " " + UserData.surname, 'profile', <UserOutlined />),
  getItem(
   getLabel("lingua"), 
    'translation',
    myLang === "it" ? <ItFlag/>:<EnFlag/>, 
    null,
    [
      getItem('Italiano', 'it', <ItFlag className="mr-10"/>),
      getItem('English', 'en', <EnFlag/>),
    ]
  ),
];
const secondaryItems = [
  getItem('Logout', 'Logout', <LogoutOutlined />, "ea-menu-logout")
];


function DashboardMenu(props) {

  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(window.location.pathname.split("/")[1]);
  
  function logout(){ 
    localStorage.clear("__ea_access_token");
    localStorage.clear("__ea_user_name");
    localStorage.clear("__ea_user_surname");
    sessionStorage.clear("__ea_access_token");
    sessionStorage.clear("__ea_user_name");
    sessionStorage.clear("__ea_user_surname");
    navigate("/");
  };
  
  function clickItem(e){
    if(e.keyPath[1] === "translation"){
      localStorage.setItem("__lang", e.key);
      window.open(window.location.href, "_self")
      return;
    }
    navigate("/" + e.key);
    setSelectedKey(e.key);
  };
  

  return (
    <>
      {/* menu principale */}
      <Menu className="ea-navigation primary" theme="light" defaultSelectedKeys={selectedKey} selectedKeys={selectedKey} mode="inline" items={items} onClick={clickItem}/>
      {/* menu secondario */}
      <Menu className="ea-navigation secondary" theme="light" mode="inline" items={secondaryItems} onClick={logout} />
    </>
  );
}

export default DashboardMenu;