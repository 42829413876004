import React from 'react';

import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { useNavigate } from 'react-router';
import { Animated } from 'react-animated-css';
import LogoExtended from '../LogoExtended';

function Register(props) {

  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  
  return (
    <div className='ea-login-wrapper vh-100 bg-gradient1'>
      <Row  align={{xl:"middle"}} justify={{xl:"between"}}>
        <Col lg={{span:12}} className='ea-login-image-area w-100 p-10 h-100 flex justify-center align-center'>
           <Animated animationIn='fadeIn' animationOut='fadeOut'>
            <img src="/ea-register.svg"/>
           </Animated>
        </Col>
        <Col lg={{span:12}} className='ea-login-form-area w-100 p-10 bg-white h-100' justify="center" align="middle">
          <div className='ea-login-form-wrapper h-100 flex column align-center justify-center'>
            <LogoExtended/>
            <p className='color-grey mb-20'>Pronto per monitorare i tuoi dati? Registrati ora</p>
            <Form
              name="normal_login"
              className="login-form"
              size='large'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Inserisci il tuo Username!',
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Inserisci una mail valida',
                  },
                ]}
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Inserisci la tua password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="repeatPassword"
                rules={[
                  {
                    required: true,
                    message: 'Inserisci la tua password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Ripeti Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Ricordami</Checkbox>
                </Form.Item>
                <a className="login-form-forgot" href="">
                  Password Dimenticata
                </a>
              </Form.Item>
        
              <Form.Item>
                <Button block shape='round' type="primary" htmlType="submit" className="login-form-button bold">
                  Registrati
                </Button>
              </Form.Item>
            </Form>
            <p>Hai già un account? <span className="color-primary bold cursor-pointer" onClick={()=>{navigate("/")}}>Accedi!</span></p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Register;