import React, { useEffect, useState } from "react";

import { Col, Row, Skeleton, Pagination, Button } from "antd";
import { useSearchParams } from "react-router-dom";

import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";

import {getLabel} from '../../helpers/Translations';
import { UserData } from "../../helpers/UserData";
import DashboardLayout from '../DashboardLayout';
import { ApiCallPost } from "../../Backoffice";
import SearchFilter from '../SearchFilter';
import AppCard from '../AppCard';

const filterJson = [
  {
    filter:"TreeSelect",
    filter_name:"sort_by",
    key:"sort_by", 
    data:[
      {
        title: getLabel("data_creazione"),
        value: 'created_at',
      },
      {
        title: getLabel("citta"),
        value: 'city',
      },
      {
        title: getLabel("indirizzo"),
        value: 'address',
      },
      {
        title:  getLabel("email"),
        value: 'email',
      },
      {
        title: getLabel("ragione_sociale"),
        value: 'company_name',
      },
      {
        title:  getLabel("nome"),
        value: 'name',
      },
      {
        title:  getLabel("cap"),
        value: 'zip_code',
      },
    ]
  },
  {
    filter:"TreeSelect",
    filter_name:"status",
    key:"enabled", 
    data:[
      {
        title: getLabel("enabled"),
        value: '1',
      },
      {
        title: getLabel("disabled"),
        value: '0',
      }
    ]
  },
  {
    filter:"TreeSelect",
    filter_name:"is_published",
    key:"is_published", 
    data:[
      {
        title: getLabel("published"),
        value: '1',
      },
      {
        title: getLabel("unpublished"),
        value: '0',
      }
    ]
  },
];

//funzione per ottenere i vari query params dall'url
const queryParams = window.location.search;

function Apps(props) {

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [endpoint, setEndPoint] = useState("apps");
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [appsData, setAppsData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(0);

  const [searchParams] = useSearchParams();

  //genero gli skeleton
  const skeletons = Array.from({
    length: rowsPerPage,
  }).map((_, i) => ({}));


  //chiamata per ricevere i dati
  useEffect(()=>{
    setLoading(true);
    
    const body = {
      token_user:UserData.accessToken,
      order_by:"created_at", 
      take:rowsPerPage,
      pagination:page,
      order_type:sort,
    };
    
    (async()=>{
      const res = await  ApiCallPost(endpoint + queryParams, JSON.stringify(body));
      if(res === "token_user_not_present"){
        console.log(res);
      }if(res === "required_fields_missing"){
        console.log(res);
      }else{
        setAppsData(res.results);
        setTimeout(()=>{
          setLoading(false)
        },200)
        setTotalRows(res.results_total_rows)
      }
    })()

  },[rowsPerPage,page,sort, endpoint])
  
  //selezionare quanti risultati per pagina
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize)
  };
  //cambio della pagina
  const onPageChange = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  //component paginazione
  const AppsPagination=()=>{
    return(
     <>
       <Col 
           xs={{span:23}}
           sm={{span:23}}
           md={{span:8}}
           align="start"
         >
           <div className="flex h-100 fs-14 gap-5 align-center">
             <Button shape="circle" onClick={sorting} icon={sort == "asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined /> }/>
             <span className="fw-5"> {rowsPerPage}  </span>
             <span> {getLabel("elementi_mostrati")} </span>
             <span className="fw-5 color-primary"> {totalRows} </span>
           </div>
         </Col>
         <Col 
           xs={{span:23}}
           sm={{span:23}}
           md={{span:16}} 
           align="end"
         >
           <Pagination  
              locale={{ items_per_page: "/ " + getLabel("pagina") }}
              pageSize={rowsPerPage}
              showSizeChanger
              pageSizeOptions={[15,30,50,100]}
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={page + 1}
              current={page + 1}
              total={totalRows}
              onChange={onPageChange}
           />
         </Col>
     </>
    )
  }
  //funzione per il sorting dati
  function sorting(){
    setSort(sort === "asc"?"desc":"asc")
  }
  return (
    <DashboardLayout>
      <section className='ea-area-apps'>
        {/* ricerca e filtri delle apps */}
        <section className='ea-search-filter-wrapper p-10'>
          <SearchFilter filterJson={filterJson} endpoint={endpoint}/>
        </section>
        {/* listing delle apps */}
        <section className='ea-apps-list'>

          <div className="p-10">
            <Row gutter={[15,15]} className="ea-card mb-10">
              <AppsPagination/>
            </Row>
          </div>

          {!loading ?
           <>
           <Row gutter={[20,20]} className="mb-20">
            {appsData.map((e, index)=>{
                return(
                  <Col 
                    key={e.id}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={8}
                  >
                    <AppCard app_data={e}/>
                  </Col>
                )
              })
            }
           </Row>
           </>
          :
            <Row gutter={[20,20]} className="mb-20"> 
              {
                skeletons.map((e, index)=>{
                  return(
                    <Col 
                      key={index}
                      xs={{span:24}}
                      md={{span:12}}
                      lg={{span:12}}
                      xl={{span:8}}
                    >
                      <div className="ea-card">
                        <Skeleton
                          active
                          avatar={{size:100}}
                          paragraph={{
                            rows: 3,
                          }}
                        />
                      </div>      
                    </Col>
                  )
                })
              }
            </Row>
          }

          <div className="p-10">
            <Row gutter={[15,15]} className="ea-card mb-10">
              <AppsPagination/>
            </Row>
          </div>

        </section>
      </section>
    </DashboardLayout>
  );
}

export default Apps;


