import { useNavigate, Navigate } from 'react-router';
import React, { useEffect } from 'react';

import { Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import { LockOutlined, MailOutlined} from '@ant-design/icons';
import { Animated } from 'react-animated-css';

import { UserData } from '../../helpers/UserData';
import {ApiCallPost} from '../../Backoffice';
import LogoExtended from '../LogoExtended';

function Login(props) {

  const accessToken = sessionStorage.getItem("__ea_access_token") ? sessionStorage.getItem("__ea_access_token"): localStorage.getItem("__ea_access_token");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const loading = () => {
    messageApi.open({
      type: 'loading',
      content: 'Caricamento in corso..',
      duration: 0,
    });
  };
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Login avvenuto con successo!',
      duration: 3,
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Questi dati non corrispondono ai nostri record',
      duration: 3,
    });
  };

  useEffect(()=>{
    const __last_path = localStorage.getItem("__last_path");
    localStorage.removeItem("__last_path");
    if(__last_path){
      navigate(__last_path);
    }
  },[])
  
  //funzione di login
  const login = (values) => {

    loading();
    const {remember, ...credentials} = values;

    (async()=>{
      const res = await  ApiCallPost("login", JSON.stringify(credentials));

      if(res.results){
        messageApi.destroy();
        success();
        localStorage.setItem("__lang","it")
        if(remember){
          localStorage.setItem("__ea_access_token",res.results.remember_token)
          localStorage.setItem("__ea_user_name",res.results.name)
          localStorage.setItem("__ea_user_surname",res.results.surname)
          window.open("/insights", "_self")
        }else{
          sessionStorage.setItem("__ea_access_token",res.results.remember_token)
          sessionStorage.setItem("__ea_user_name",res.results.name)
          sessionStorage.setItem("__ea_user_surname",res.results.surname)
          window.open("/insights", "_self")
        }
      }else{
        messageApi.destroy();
        error();
      }
      
    })()
  };


  return (
  <>
      {
        accessToken 
        ? 
          <Navigate to="/insights"/>
        :
          <div className='ea-login-wrapper vh-100 bg-gradient1'>
          <Row  align={{xl:"middle"}} justify={{xl:"between"}}>
            <Col lg={{span:12}} className='ea-login-image-area w-100 p-10 h-100 flex justify-center align-center'>
                <Animated animationIn='fadeIn' animationOut='fadeOut'>
                <img src="/ea-login.svg"/>
                </Animated>       
              </Col>
            <Col lg={{span:12}} className='ea-login-form-area w-100 p-10 bg-white h-100' justify="center" align="middle">
              <div className='ea-login-form-wrapper h-100 flex column align-center justify-center'>
                <LogoExtended/>
                <p className='color-grey mb-20'>Accedi con le tue credenziali ed inizia a monitorare i tuoi dati</p>
                <Form
                  name="normal_login"
                  className="login-form"
                  size='large'
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={login}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Inserisci la tua E-Mail!',
                      },
                      {
                        type:"email",
                        message:"Inserisci una Mail valida"
                      }
                    ]}
                  >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="mario.rossi@mail.it" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Inserisci la tua password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Ricordami</Checkbox>
                    </Form.Item>
                    <a className="login-form-forgot" href="">
                      Password Dimenticata
                    </a>
                  </Form.Item>
            
                  <Form.Item>
                    <Button block shape='round' type="primary" htmlType="submit" className="login-form-button bold">
                      Accedi
                    </Button>
                  </Form.Item>
                </Form>
                <p>Non hai un account?  <span className="color-primary bold cursor-pointer" onClick={()=>{navigate("/register")}}>Registrati ora!</span></p>
              </div>
            </Col>
          </Row>
          {contextHolder}
          </div>
      }
  </>
  );
}

export default Login;