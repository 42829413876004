const ThemeMenu = [
  {
    "name":"Pagine",
    "value":"apps_pages",
    "key":"apps_pages",
    "icon":"FileOutlined"
  },
  {
    "name":"Sedi",
    "value":"apps_locations",
    "key":"apps_locations",
    "icon":"CompassOutlined"
  },
  {
    "name":"Fotogallery",
    "value":"apps_galleries",
    "key":"apps_galleries",
    "icon":"PictureOutlined"
  },
  {
    "name":"Moduli",
    "value":"apps_forms",
    "key":"apps_forms",
    "icon":"FormOutlined"
  },
  {
    "name":"E-Mails",
    "value":"apps_emails",
    "key":"apps_emails",
    "icon":"MailOutlined"
  },
  {
    "name":"Quiz",
    "value":"apps_quizzes",
    "key":"apps_quizzes",
    "icon":"MessageOutlined"
  },
  {
    "name":"Liste di attesa",
    "value":"apps_waiting_queues_settings",
    "key":"apps_waiting_queues_settings",
    "icon":"FieldTimeOutlined"
  },
  {
    "name":"Beacons",
    "value":"apps_beacons",
    "key":"apps_beacons",
    "icon":"ShakeOutlined"
  },
  {
    "name":"Marketplaces",
    "value":"apps_marketplaces",
    "key":"apps_marketplaces",
    "icon":"ShopOutlined"
  },
  {
    "name":"Home",
    "value":"apps_homes",
    "key":"apps_homes",
    "icon":"HomeOutlined"
  }
]

const ToolsMenu = [
  {
    "name":"Pos",
    "value":"loyalty_pos",
    "key":"loyalty_pos",
    "icon":"CalculatorOutlined"
  },
  {
    "name":"Clienti",
    "value":"apps_customers",
    "key":"apps_customers",
    "icon":"TeamOutlined"
  },
  {
    "name":"Transazioni",
    "value":"loyalty_transactions",
    "key":"loyalty_transactions",
    "icon":"InteractionOutlined"
  },
  {
    "name":"Notifiche",
    "value":"apps_devices_notifications",
    "key":"apps_devices_notifications",
    "icon":"BellOutlined"
  },
  {
    "name":"Campagne",
    "value":"loyalty_programs",
    "key":"loyalty_programs",
    "icon":"IdcardOutlined"
  },
  {
    "name":"Coupons",
    "value":"shops_coupons",
    "key":"shops_coupons",
    "icon":"WalletOutlined"
  },
  {
    "name":"Posta",
    "value":"apps_inboxes",
    "key":"apps_inboxes",
    "icon":"InboxOutlined"
  },
  {
    "name":"Prodotti",
    "value":"shops_products",
    "key":"shops_products",
    "icon":"ShoppingCartOutlined"
  },
  {
    "name":"Ordini",
    "value":"shops_orders",
    "key":"shops_orders",
    "icon":"EuroOutlined"
  },
  {
    "name":"Calendario",
    "value":"apps_calendars",
    "key":"apps_calendars",
    "icon":"CalendarOutlined"
  },
  {
    "name":"Prenotazioni",
    "value":"apps_customers_waiting_queues",
    "key":"apps_customers_waiting_queues",
    "icon":"BookOutlined"
  }
]

export {ThemeMenu, ToolsMenu}