import React, { useState } from 'react';

import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, message } from 'antd';
import { Routes, Route } from 'react-router';

import PrivateRoutes from './helpers/PrivateRoutes';
import Insights from './components/pages/Insights';
import Register from './components/pages/Register';
import AppEdit from './components/pages/AppEdit';
import Profile from './components/pages/Profile';
import Login from './components/pages/Login';
import Tools from './components/pages/Tools';
import Theme from './components/pages/Theme';
import Apps from './components/pages/Apps';

import AppEditReferFriend from './components/pages/AppEditReferFriend';
import AppEditGoogleLogin from './components/pages/AppEditGoogleLogin';
import AppEditSocials from './components/pages/AppEditSocials';
import AppEditTheme from './components/pages/AppEditTheme';
import AppEditTerms from './components/pages/AppEditTerms';
import AppEditSmtp from './components/pages/AppEditSmtp';
import AppEditSms from './components/pages/AppEditSms';
import './index.scss';

//imports dei componenti tema-pagine
import ThemeEditPageBeacons from './components/pages/ThemeArea/ThemePages/ThemeEditPageBeacons';
import ThemePagesCategoriesCreate from './components/pages/ThemeArea/ThemePages/ThemePagesCategoriesCreate';
import ThemePagesCategoriesEdit from './components/pages/ThemeArea/ThemePages/ThemePagesCategoriesEdit';
import ThemePagesCategories from './components/pages/ThemeArea/ThemePages/ThemePagesCategories';
import ThemeEditPageGroups from './components/pages/ThemeArea/ThemePages/ThemeEditPageGroups';
import ThemePageCreate from './components/pages/ThemeArea/ThemePages/ThemePageCreate';
import ThemeEditPage from './components/pages/ThemeArea/ThemePages/ThemeEditPage';
import ThemePages from './components/pages/ThemeArea/ThemePages/ThemePages';

import { Context } from './helpers/Context';

function App() {
  
  const [messageApi, contextHolder] = message.useMessage();

  const eaAlert = (content, type) =>{
    messageApi.open({
      content,
      type
    })
  }

  return(
    <ConfigProvider
      theme={
        {
          token:{
            colorPrimary:"#f95f1c",
            colorError:"#f33155",
            colorSuccess:"#7ace4c",
            colorLink:"#41b3f9",
            colorWarning:"#ffbb44"
          }
        }
      }
    >
      <Context.Provider
        value={{
          eaAlert,
        }}
      >
        {contextHolder}
        <BrowserRouter>
          <Routes>
            
            {/* routes pubbliche */}
        
            <Route path='/register' element={<Register/>}/> 
            <Route path='/*' element={<Login/>}/>
            <Route path='/' element={<Login/>}/>
        
            {/* routes private */}
            <Route element={<PrivateRoutes/>}>
              {/* routes principali */}
              <Route path='/insights' element={<Insights/>}/>
              <Route path='/profile' element={<Profile/>}/>
              <Route path='/tools/:id' element={<Tools/>}/>
              <Route path='/:id' element={<Theme/>}/>
              <Route path='/apps' element={<Apps/>}/>
              {/* routes principali */}
        
              {/* routes tema */}

                {/* ------------- routes delle pagine (modifica, crea, gruppi di pagine) -------------*/}
                <Route path='/apps_pages_customers_groups/:pageId/edit' element={<ThemeEditPageGroups/>}/>
                <Route path='/apps_pages_categories/:id/create' element={<ThemePagesCategoriesCreate/>}/>
                <Route path='/apps_pages_categories/:id/edit' element={<ThemePagesCategoriesEdit/>}/>
                <Route path='/apps_pages_beacons/:pageId' element={<ThemeEditPageBeacons/>}/>
                <Route path='/apps_pages_categories/:id' element={<ThemePagesCategories/>}/>
                <Route path='/apps_pages/:pageId/edit' element={<ThemeEditPage/>}/>
                <Route path='/apps_pages/:id/create' element={<ThemePageCreate/>}/>
                <Route path='/apps_pages/:id' element={<ThemePages/>}/>
                {/* ------------- routes delle pagine (modifica, crea, gruppi di pagine) -------------*/}

              {/* routes tema */}
        
              {/* routes strumenti */}
        
              {/* routes strumenti */}
        
              {/* routes delle impostazioni generiche */}
              <Route path='/apps/:id/refer_friend' element={<AppEditReferFriend/>}/>
              <Route path='/apps/:id/google_login' element={<AppEditGoogleLogin/>}/>
              <Route path='/apps/:id/socials' element={<AppEditSocials/>}/>
              <Route path='/apps/:id/theme' element={<AppEditTheme/>}/>
              <Route path='/apps/:id/terms' element={<AppEditTerms/>}/>
              <Route path='/apps/:id/smtp' element={<AppEditSmtp/>}/>
              <Route path='/apps/:id/sms' element={<AppEditSms/>}/>
              <Route path=':id/edit' element={<AppEdit/>}/>
              {/* routes delle impostazioni generiche */}
        
            </Route>
        
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </ConfigProvider>
  )
}

export default App;
