import React from 'react';

function ItFlag(props) {
  return (
    <span role="img" aria-label="user" className=" ant-menu-item-icon">
      <img src='/it-flag.jpg'/>
    </span>
  );
}
function EnFlag(props) {
  return (
    <span role="img" aria-label="user" className=" ant-menu-item-icon">
      <img src='/en-flag.jpg'/>
    </span>
  );
}

export {ItFlag, EnFlag};