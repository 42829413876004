import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Checkbox,
  Button,
  Space,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Spin,
} from 'antd';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';



function AppEditSms(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [testNumber, setTestNumber] = useState("");
  const [appToken, setAppToken] = useState("");
  const [render, setRender] = useState(false);
  const [appName, setAppName] = useState("");
  const [status,setStatus] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;


  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            appId+'/edit'), 
        getItem(getLabel( "tema"),                'apps/'+appId+'/theme'),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        'apps/'+appId+'/smtp'),
        getItem(          "SMS",                  `${window.location.pathname}`),
        getItem(getLabel( "widget_refer_friend"), 'apps/'+appId+'/refer_friend'),
        getItem(getLabel( "google_login"),        'apps/'+appId+'/google_login'),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=sms", JSON.stringify(body));
      if(res.response === "ok"){

        const setting = res.results[0];
        
        //imposto i valori di imput già configurati
        form.setFieldsValue(
          {
            mobyt_password: setting.mobyt_password,
            mobyt_sendername: setting.mobyt_sendername,
            mobyt_sms_activation: setting.mobyt_sms_activation,
            mobyt_username: setting.mobyt_username,
            sms_provider: setting.sms_provider
          }
        )
        setRender(true);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])


  //input di ricerca per gruppi di pagine e sottoapps
  const onChange = (value) => {
  };

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

   //funzione test sms
   const sendTest = () => {
    setStatus(!testNumber ? "error" : "");
  }

  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          <Col 
            xs={24} 
            sm={24} 
            md={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          <Col  
            xs={24} 
            sm={24} 
            md={20} 
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              SMS
              <Divider/>
            </div>
              {/* modifica form */}
              {render ?
                  <Form
                    form={form}
                    labelCol={{ 
                      xs:{
                        span:8
                      },
                      lg:{
                        span: 8 
                      },
                      xxl:{
                        span: 6 
                      }
                    }}
                    wrapperCol={{ 
                      xs:{
                        span:24
                      },
                      lg:{
                        span: 16
                      },
                      xxl:{
                        span: 18
                      }
                    }}
                    layout='horizontal'
                    name="pageSetting"
                    onFinish={onFinish}
                  >

                  {/* provider sms dell'app */}
                  <Form.Item
                    name='sms_provider'
                    label= {"SMS Provider"}
                  >
                    <Select
                        optionFilterProp="children"
                        onChange={onChange}
                        options={[
                          {
                            "value":"mobyt",
                            "label":"MOBYT"
                          },
                          {
                            "value":"skebby",
                            "label":"SKEBBY"
                          },
                          {
                            "value":"totalconnect",
                            "label":"TOTALCONNECT"
                          },
                          {
                            "value":"smshosting",
                            "label":"SMSHOSTING"
                          },
                        ]}
                      />
                  </Form.Item>
                  {/* provider sms dell'app */}

                  {/* server sms dell'app */}
                  <Form.Item
                    name='mobyt_sendername'
                    label={getLabel("sender")}
                    rules={[
                      {
                        required: true,
                        message:getLabel("campo_obbligatorio")
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  {/* server sms dell'app */}

                  {/* nome utente sms dell'app */}
                  <Form.Item
                    name='mobyt_username'
                    label={getLabel("username")}
                    rules={[
                      {
                        required: true,
                        message:getLabel("campo_obbligatorio")
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  {/* nome utente sms dell'app */}

                  {/* password sms dell'app */}
                  <Form.Item
                    name='mobyt_password'
                    label="Password" 
                    type="password"
                    placeholder="Password"
                    rules={[
                      {
                        required: true,
                        message:getLabel("campo_obbligatorio")
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  {/* password sms dell'app */}

                  {/* attiva clienti con sms */}
                  <Form.Item
                      name='mobyt_sms_activation'
                      valuePropName="checked"
                      label={getLabel("attiva_con_sms")}
                    >
                      <Checkbox>{getLabel("attiva_con_sms")}</Checkbox>
                  </Form.Item>
                  {/* attiva clienti con sms */}

                  <Divider/>
                  {/*salva o salva e rimani*/}
                  <Form.Item 
                    label=" " 
                    className='save-form'
                  >
                    <div className='flex gap-10 flex-wrap'>
                      <Button type="primary" size="medium" htmlType="submit">
                        {getLabel("salva")}
                      </Button>
                      <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                        {getLabel("salva_rimani")}
                      </Button>
                    </div>
                  </Form.Item>
                  
                  </Form>
                :
                <div className='w-100 flex justify-center'>
                  <Spin/>
                </div>
              }

            <div className='w-100 px-10 fs-18 fw-5 mt-50'>
              Test SMS
              <Divider/>
            </div>
            
            {/* email test */}
            <Space.Compact
              style={{
                width: '100%',
              }}
            >
              <Input 
                type='email' 
                onChange={(e) => {
                  setTestNumber(e.target.value)
                }}
                value={testNumber}
                status={status} 
                placeholder='+3918304190'
              />
              <Button type="primary" onClick={sendTest}>{getLabel("send")}</Button>
            </Space.Compact>
            {/* email test */}

          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEditSms;