import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Spin,
  Button,
} from 'antd';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';



function AppEditGoogleLogin(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [render, setRender] = useState(false);
  const [appName, setAppName] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;

  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            appId+'/edit'), 
        getItem(getLabel( "tema"),                'apps/'+appId+'/theme'),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        'apps/'+appId+'/smtp'),
        getItem(          "SMS",                  'apps/'+appId+'/sms'),
        getItem(getLabel( "widget_refer_friend"), 'apps/'+appId+'/refer_friend'),
        getItem(getLabel( "google_login"),        `${window.location.pathname}`),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=google_login", JSON.stringify(body));
      if(res.response === "ok"){

        const setting = res.results[0].google_login;

        //imposto i valori di imput già configurati
        form.setFieldsValue(
          {
            client_id: setting.client_id,
            client_secret: setting.client_secret,
            enabled: setting.enabled,
            redirect_uri: setting.redirect_uri
          }
        )
        setRender(true);

      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])

  //input di ricerca per gruppi di pagine e sottoapps
  const onChange = (value) => {
  };
  const onSearch = (value) => {
  };

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          <Col 
            xs={24} 
            sm={24} 
            md={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          <Col  
            xs={24} 
            sm={24} 
            md={20} 
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("google_login")}
              <Divider/>
            </div>
            {/* modifica form */}
            {render ?
              <Form
                form={form}
                labelCol={{ 
                  xs:{
                    span:8
                  },
                  lg:{
                    span: 8 
                  },
                  xxl:{
                    span: 6 
                  }
                }}
                wrapperCol={{ 
                  xs:{
                    span:24
                  },
                  lg:{
                    span: 16
                  },
                  xxl:{
                    span: 18
                  }
                }}
                layout='horizontal'
                name="pageSetting"
                onFinish={onFinish}
              >

                <Form.Item
                  name='client_id'
                  label={"Google Client ID"}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  name='client_secret'
                  label={"Google Client Secret"}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  name='redirect_uri'
                  label={"Google Redirect URL"}
                  rules={[
                    {
                      type: 'url',
                      message:"URL " + getLabel("invalid")
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    name='enabled'
                    valuePropName="checked"
                    label={getLabel("status")}
                  >
                    <Checkbox>{getLabel("enabled")}</Checkbox>
                </Form.Item>
                
                <Divider/>
                {/*salva o salva e rimani*/}
                <Form.Item 
                  label=" " 
                  className='save-form'
                >
                  <div className='flex gap-10 flex-wrap'>
                    <Button type="primary" size="medium" htmlType="submit">
                      {getLabel("salva")}
                    </Button>
                    <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                      {getLabel("salva_rimani")}
                    </Button>
                  </div>
                </Form.Item>

              </Form>

              
            :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }

          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEditGoogleLogin;