import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { DownOutlined, CopyOutlined, DeleteOutlined, EditOutlined, QrcodeOutlined, CheckOutlined, CloseOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { Button, Dropdown, Space, Table, Tooltip } from 'antd';

import { getLabel } from '../../../../helpers/Translations';
import { UserData } from '../../../../helpers/UserData';
import TableActions from '../../../tables/TableActions';
import DashboardLayout from '../../../DashboardLayout';
import { AppById } from '../../../../helpers/AppById';
import { ApiCallPost } from '../../../../Backoffice';
import SearchFilter from '../../../SearchFilter';
import Breadcrumbs from '../../../Breadcrumbs';

const items = [
  {
    label: getLabel("nuova") + " " + getLabel("pagina"),
    key: `/apps_pages/${UserData.appId}/create`,
  },
  {
    label: getLabel("gruppi_pagine"),
    key: `/apps_pages_categories/${UserData.appId}`,
   
  },
  {
    label: getLabel("nuovo") + " " + getLabel("gruppo"),
    key: `/apps_pages_categories/${UserData.appId}/create`,
  },
];
const menuProps = {
  items,
  onClick: (e) => {window.open(e.key, "_self")},
};


function getQueryParams(search) {
  const queryString = (search||window.location.search).replace('?', '');
  return queryString.split('&').reduce((prev, cur) => {
      const [key, value] = cur.split('=');
      prev[key] = decodeURI(value);
      return prev;
  }, {});
}

const queryParams = window.location.search;



function ThemePages(props) {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [endpoint, setEndpoint] = useState("pages");
  const [groupPages, setGroupPages] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [appToken, setAppToken] = useState("");
  const [pageList, setPageList] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [appName, setAppName] = useState("");
  const [filter, setFilter] = useState([]); //i filtri sono da risettare quando arrivano i dati dinamici, quindi sono in uno useeffect
  const [load, setLoad] = useState(false);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const appId = params.id;


  // -------------- gestione dei filtri delle pagine --------------

  useEffect(()=>{
    const filterJson = [
      {
        filter:"TreeSelect",
        filter_name:"order_by",
        key:"order_by", 
        data:[
          {
            title: getLabel("data_creazione"),
            value: 'created_at',
          },
          {
            title: "Id",
            value: 'id',
          },
          {
            title: getLabel("position"),
            value: 'position',
          },
          {
            title:  getLabel("titolo"),
            value: 'title',
          },
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"status",
        key:"enabled", 
        data:[
          {
            title: getLabel("enabled"),
            value: '1',
          },
          {
            title: getLabel("disabled"),
            value: '0',
          }
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"in_home",
        key:"in_home", 
        data:[
          {
            title: getLabel("yes"),
            value: '1',
          },
          {
            title: "No",
            value: '0',
          }
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"in_menu",
        key:"in_menu", 
        data:[
          {
            title: getLabel("yes"),
            value: '1',
          },
          {
            title: "No",
            value: '0',
          }
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"logged",
        key:"logged", 
        data:[
          {
            title: getLabel("yes"),
            value: '1',
          },
          {
            title: "No",
            value: '0',
          }
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"not_logged",
        key:"not_logged", 
        data:[
          {
            title: getLabel("yes"),
            value: '1',
          },
          {
            title: "No",
            value: '0',
          }
        ]
      },
      {
        filter:"TreeSelect",
        filter_name:"group_id",
        key:"group_id", 
        data: groupPages
      },
    ];
    setFilter(filterJson);
  },[groupPages])


  // -------------- gestione dei colori --------------
  const [buttonColor, setButtonColor] = useState("");

  // -------------- definisco le colonne della tabella con anche il set di impostazioni/chiamate nei riordini  -------------- 
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      responsive: ['sm','md','lg', 'xl'],
      sorter: (a,b) => {
        setSort(sort == "asc" ? "desc" : "asc");
        setOrderBy("id");
      },
    },
    {
      title: getLabel("icona"),
      dataIndex: 'icon',
      responsive: ['lg', 'xl'],
      render: (_,  record) => (
        record.icon && <div className='ea-table-img page-icon' style={{backgroundImage:`url(${record.base_url+record.icon})`,backgroundColor:buttonColor}} />
      ),
    },
    {
      title: getLabel("copertina"),
      dataIndex: 'media',
      responsive: ['lg', 'xl'],
      render: (_,  record) => (
        record.media && <div className='ea-table-img' style={{backgroundImage:`url(${record.base_url+record.media})`}} />
      ),
    },
    {
      title: getLabel("titolo"),
      dataIndex: 'title',
      sorter: (a,b) => {
        setSort(sort == "asc" ? "desc" : "asc");
        setOrderBy("title");
      },
    },
    {
      title:"In Home",
      dataIndex: 'in_home',
      responsive: ['md','lg', 'xl'],
      render: (_,  record) => (
        <div>
        {record.in_home == 1 ?
          <CheckCircleFilled className='color-green fs-25'/>
          :
          <CloseCircleFilled className='color-red  fs-25'/>
        }
        </div>
      ),
    },
    {
      title:"In Menu",
      dataIndex: 'in_menu',
      responsive: ['md','lg', 'xl'],
      render: (_,  record) => (
        <div>
        {record.in_menu == 1 ?
            <CheckCircleFilled className='color-green fs-25'/>
            :
            <CloseCircleFilled className='color-red  fs-25'/>
        }
        </div>
      ),
    },
    {
      title:getLabel("position"),
      dataIndex: 'position',
      sorter: (a,b) => {
        setSort(sort == "asc" ? "desc" : "asc");
        setOrderBy("position");
      },
      responsive: ['md','lg', 'xl'],
    },
    {
      title: getLabel("azioni"),
      render: (_,record) => (
        <TableActions url={`/apps_pages/${record.id}/edit`}/>
      ),
    },
  ];

  //  -------------- con l'id app dell'url recupero nome e token e li setto  -------------- 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //  --------------  quando arrivano i dati delle briciole le setto  -------------- 
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:appName,
        url:"/"+UserData.appId
      },
      {
        name:getLabel("apps_pages")
      }
    ])
  },[appName])

  //  --------------  chiamata per ricevere i dati pagine  -------------- 
  useEffect(()=>{

    if(!appToken) return;

    setLoad(true);
    
    const body = {
      token_user:UserData.accessToken,
      order_by:orderBy && orderBy,
      token_key:appToken,
			take:rowsPerPage,
      pagination:page,
      order_type:sort,
    };
    (async()=>{
      const res = await  ApiCallPost(endpoint + queryParams, JSON.stringify(body));
     
      if(res === "token_user_not_present"){
        console.log(res);
      }if(res === "required_fields_missing"){
        console.log(res);
      }else{

        setTotalRows(res.results_total_rows);

        if(res.results){
          res.results.forEach(e => {
            e["key"] = e.id;
          }); //aggiungo una key alle informazioni per evitare errori in react
        }
        setPageList(res.results)
        setLoad(false);
      }
    })()

  },[appToken, UserData.accessToken, rowsPerPage, page, sort])

  //  --------------  recupero i dati delle impostazioni colore  -------------- 
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken,
      "pagination":"1",
			"take":"10",
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=theme", JSON.stringify(body));
     
      if(res.response === "ok"){

        var appThemeSettings = res.results[0];
        //imposto i valori di imput già configurati

        sessionStorage.setItem("__ea_app_button_color", appThemeSettings.button_color);
        setButtonColor(appThemeSettings.button_color);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  // --------------  recupero i dati dei gruppi di pagine  -------------- 
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("pages_groups", JSON.stringify(body));

      if(res.response === "ok"){
        
        var data = [...res.results];

        data.forEach(e => {
          e.title = e.name;
          e.value = e.id
          delete e.updated_at
          delete e.created_at
          delete e.id_company
          delete e.id_parent
          delete e.enabled
          delete e.id_app
          delete e.name
          delete e.id
        });

        setGroupPages(data);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

 // --------------  selezionare quanti risultati per pagina  -------------- 
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize)
  };
  // --------------  cambio della pagina  -------------- 
  const onPageChange = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  return (
    <DashboardLayout>
      <Breadcrumbs  navigation={breadcrumbs}/>
      <div className='mb-20 mt-10'>
        <SearchFilter endpoint={appId} filterJson={filter}/>
      </div>
      <div className='ea-card'>
        <div className='flex w-100 space-between py-20'>
          <div className='fs-18 fw-5'>{getLabel("apps_pages")}</div>
          <Dropdown menu={menuProps}>
            <Button type='primary' className='bg-blue'> 
              <Space>
                Gestione
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
          <Table
            columns={columns}
            dataSource={pageList}
            loading={load}
            pagination={{
              onShowSizeChange:onShowSizeChange,
              pageSizeOptions:[15,30,50,100],
              defaultCurrent:page + 1,
              position: ["topRight", "bottomRight"],
              onChange:onPageChange,
              pageSize:rowsPerPage,
              current:page + 1,
              total:totalRows,
            }}
          />
      </div>
    </DashboardLayout>
  );
}

export default ThemePages;

