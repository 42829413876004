import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Space,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Button,
  Spin,
} from 'antd';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';



function AppEditSmtp(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [testMail, setTestMail] = useState("");
  const [render, setRender] = useState(false);
  const [appName, setAppName] = useState("");
  const [status, setStatus] = useState("")
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;


  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            appId+'/edit'), 
        getItem(getLabel( "tema"),                'apps/'+appId+'/theme'),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        `${window.location.pathname}`),
        getItem(          "SMS",                  'apps/'+appId+'/sms'),
        getItem(getLabel( "widget_refer_friend"), 'apps/'+appId+'/refer_friend'),
        getItem(getLabel( "google_login"),        'apps/'+appId+'/google_login'),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=smtp", JSON.stringify(body));
      if(res.response === "ok"){

        const appSmtpSettings = res.results[0];
        console.log(appSmtpSettings);
        //setto le multiselezioni
       

        //imposto i valori di imput già configurati
        form.setFieldsValue(
          {
            "smtp_crypt":appSmtpSettings.smtp_crypt,
            "smtp_mail":appSmtpSettings.smtp_mail,
            "smtp_password":appSmtpSettings.smtp_password,
            "smtp_port":appSmtpSettings.smtp_port,
            "smtp_server":appSmtpSettings.smtp_server,
            "smtp_username":appSmtpSettings.smtp_username,
          }
        )
        setRender(true);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //input di ricerca per gruppi di pagine e sottoapps
  const onChange = (value) => {
  };

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])


  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

  //funzione test email
  const sendTest = () => {
    setStatus(!testMail ? "error" : "");

  }

  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          <Col 
            xs={24} 
            sm={24} 
            md={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          <Col  
            xs={24} 
            sm={24} 
            md={20} 
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("apps_inboxes")}
              <Divider/>
            </div>
            {/* modifica form */}
            {render ? 
              <Form
                form={form}
                labelCol={{ 
                  xs:{
                    span:8
                  },
                  lg:{
                    span: 8 
                  },
                  xxl:{
                    span: 6 
                  }
                }}
                wrapperCol={{ 
                  xs:{
                    span:24
                  },
                  lg:{
                    span: 16
                  },
                  xxl:{
                    span: 18
                  }
                }}
                layout='horizontal'
                name="pageSetting"
                onFinish={onFinish}
              >

                {/* email smtp dell'app */}
                <Form.Item
                  name='smtp_mail'
                  label={getLabel("email")}
                  rules={[
                    {
                      required: true,
                      message:getLabel("campo_obbligatorio")
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                {/* email smtp dell'app */}

                {/* server smtp dell'app */}
                <Form.Item
                  name='smtp_server'
                  label="Server SMTP"
                  rules={[
                    {
                      required: true,
                      message:getLabel("campo_obbligatorio")
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                {/* server smtp dell'app */}

                {/* nome utente smtp dell'app */}
                <Form.Item
                  name='smtp_username'
                  label={getLabel("username") + " SMTP"}
                  rules={[
                    {
                      required: true,
                      message:getLabel("campo_obbligatorio")
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                {/* nome utente smtp dell'app */}

                {/* password smtp dell'app */}
                <Form.Item
                  name='smtp_password'
                  label="Password SMTP" 
                  type="password"
                  placeholder="Password"
                  rules={[
                    {
                      required: true,
                      message:getLabel("campo_obbligatorio")
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                {/* password smtp dell'app */}

                
                {/* Criptaggio smtp dell'app */}
                <Form.Item
                  name='smtp_crypt'
                  label= {getLabel("crypt")}
                  rules={[
                    {
                      required: true,
                      message:getLabel("campo_obbligatorio")
                    },
                  ]}
                >
                  <Select
                      optionFilterProp="children"
                      onChange={onChange}
                      options={[
                        {
                          "value":"ssl",
                          "label":"SSL"
                        },
                        {
                          "value":"tls",
                          "label":"TLS"
                        },
                      ]}
                    />
                </Form.Item>
                {/* Criptaggio smtp dell'app */}

                
                {/* porta smtp dell'app */}
                <Form.Item
                  name='smtp_port'
                  label={getLabel("port")+ " SMTP"}
                >
                  <InputNumber min={0}/>
                </Form.Item>
                {/* porta smtp dell'app */}

                <Divider/>
                {/*salva o salva e rimani*/}
                <Form.Item 
                  label=" " 
                  className='save-form'
                >
                  <div className='flex gap-10 flex-wrap'>
                    <Button type="primary" size="medium" htmlType="submit">
                      {getLabel("salva")}
                    </Button>
                    <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                      {getLabel("salva_rimani")}
                    </Button>
                  </div>
                </Form.Item>

              </Form>
            :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }
            <div className='w-100 px-10 fs-18 fw-5 mt-50'>
              Test Smtp
              <Divider/>
            </div>
            
            {/* email test */}
            <Space.Compact
              style={{
                width: '100%',
              }}
            >
              <Input 
                type='email' 
                onChange={(e) => {
                  setTestMail(e.target.value)
                }}
                value={testMail}
                status={status} 
                placeholder='mario.rossi@mail.it'
              />
              <Button type="primary" onClick={sendTest}>{getLabel("send")}</Button>
            </Space.Compact>
            {/* email test */}

          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEditSmtp;