import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { DownOutlined, CopyOutlined, DeleteOutlined, EditOutlined, QrcodeOutlined, CheckOutlined, CloseOutlined, CheckCircleFilled, CloseCircleFilled, PlusOutlined } from '@ant-design/icons';

import { Button, Checkbox, Col, ConfigProvider, Divider, Dropdown, Form, Input, Row, Space, Table, Tooltip } from 'antd';

import { getLabel } from '../../../../helpers/Translations';
import { UserData } from '../../../../helpers/UserData';
import DashboardLayout from '../../../DashboardLayout';
import Breadcrumbs from '../../../Breadcrumbs';


const queryParams = window.location.search;

function ThemePagesCategoriesCreate(props) {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appName, setAppName] = useState("");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const appId = params.id;


  //  --------------  quando arrivano i dati delle briciole le setto  -------------- 
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:UserData.appName,
        url:"/"+UserData.appId
      },
      {
        name:getLabel("apps_pages"),
        url:"/apps_pages/"+UserData.appId
      },
      {
        name:getLabel("gruppi_pagine"),
        url:"/apps_pages_categories/"+UserData.appId
      },
      {
        name:getLabel("nuovo")
      },
    ])
  },[appName])

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    <DashboardLayout>
      <Breadcrumbs  navigation={breadcrumbs}/>
      <div className='ea-card mt-20'>
        <div className='flex w-100 space-between py-20'>
          <Row>
            <Col span={24}>
            <div className='fs-18 fw-5 mb-20'>{getLabel("nuovo")}</div>
            <Divider/>

            <Form
            form={form}
            labelCol={{ 
              xs:{
                span:8
              },
              lg:{
                span: 8 
              },
              xxl:{
                span: 6 
              }
            }}
            wrapperCol={{ 
              xs:{
                span:24
              },
              lg:{
                span: 16
              },
              xxl:{
                span: 18
              }
            }}
            layout='horizontal'
            name="pageSetting"
            onFinish={onFinish}
          >
            {/* Nome */}
            <Form.Item
              name='name'
              label={getLabel("nome")}
              rules={[
                {
                  required: true,
                  message:getLabel("campo_obbligatorio")
                },
              ]}
            >
              <Input/>
            </Form.Item>

            {/*abilitato */}
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7ace4c"
                },
              }}
            >
              <Form.Item
                valuePropName="checked"
                name='enabled'
                className='hide-input-label'
                label={" "}
              >
                <Checkbox>{getLabel("enabled")}</Checkbox> 
              </Form.Item>
            </ConfigProvider>

            {/*salva o salva e rimani*/}
            <Form.Item 
              label=" " 
              className='save-form'
            >
              <div className='flex gap-10 flex-wrap'>
                <Button type="primary" size="medium" htmlType="submit">
                  {getLabel("salva")}
                </Button>
              </div>
            </Form.Item>

          </Form>
            </Col>
          </Row>

        </div>
      </div>
    </DashboardLayout>
  );
}

export default ThemePagesCategoriesCreate;

