import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { 
  ConfigProvider,
  InputNumber,
  Popconfirm,
  Checkbox,
  Divider,
  message,
  Tooltip,
  Upload,
  Select,
  Button,
  Modal,
  Radio, 
  Input,
  Menu, 
  Form,
  Spin,
  Col,
  Row,
  Tabs, 
} from 'antd';

import { getLabel, myLang } from '../../../../helpers/Translations';
import { UserData } from '../../../../helpers/UserData';
import DashboardLayout from '../../../DashboardLayout';
import { ApiCallPost } from '../../../../Backoffice';
import Breadcrumbs from '../../../Breadcrumbs';



function ThemeEditPageGroups(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageGroup, setPageGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [render, setRender] = useState(false);
  const [form] = Form.useForm();

  const params = useParams();
  const pageId = params.pageId;
  const navigate = useNavigate();

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

// ----------  recupero i dati della pagina ---------- 
useEffect(()=>{

  if(!UserData.appToken) return;

  setRender(false);
  
  const body = {
    token_user:UserData.accessToken,
    token_key:UserData.appToken,
  };


  (async()=>{
    const res = await  ApiCallPost("page?page_id="+pageId, JSON.stringify(body));
    
    if(res === "token_user_not_present"){
      console.log(res);
    }if(res === "required_fields_missing"){
      console.log(res);
    }else{

      console.log(res);
      var data = res.results[0];
      setPageData(data);
      setRender(true);

    }

  })()

},[UserData.accessToken])


  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel("generale"), `apps_pages/${pageId}/edit`), 
        getItem(getLabel("gruppi_clienti"), `${window.location.pathname}`),
        getItem('Beacons', `apps_pages_beacons/${pageId}`),
      ], 
      'group'),
  ]

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:UserData.appName,
        url:"/"+UserData.appId
      },
      {
        name:getLabel("apps_pages"),
        url:"/apps_pages/"+UserData.appId
      },
      {
        name:pageData.title
      },
      {
        name:getLabel("gruppi_clienti")
      },
    ])
  },[pageData])


  return (
    <DashboardLayout>
      <Breadcrumbs navigation={breadcrumbs}/>
      <div className='ea-card mt-10'>
        <Row>
          {/* menu laterale della modifica */}
          <Col xs={{span:24}} sm={{span:24}} md={{span:4}}>
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          {/* area di input modifiche */}
          <Col  xs={{span:24}} sm={{span:24}} md={{span:20}} className="p-10">
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("gruppi_clienti")}
              <Divider/>
            </div>

            {/* inizio del form della modifica pagina */}
            {render ?
              <Form
                form={form}
                labelCol={{ 
                  xs:{
                    span:8
                  },
                  lg:{
                    span: 6 
                  },
                  xxl:{
                    span: 4 
                  }
                }}
                wrapperCol={{ 
                  xs:{
                    span:24
                  },
                  lg:{
                    span: 18
                  },
                  xxl:{
                    span: 20
                  }
                }}
                layout='horizontal'
                name="pageSetting"
                onFinish={onFinish}
              >
               
                {/*salva o salva e rimani*/}
                <Form.Item 
                  label=" " 
                  className='save-form'
                >
                  <div className='flex gap-10 flex-wrap'>
                    <Button type="primary" size="medium" htmlType="submit">
                    {getLabel("salva")}
                    </Button>
                    <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                    {getLabel("salva_rimani")}
                    </Button>
                  </div>
                </Form.Item>

              </Form>
            :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }
          </Col>
        </Row>
      </div>   
    </DashboardLayout>
  );
}

export default ThemeEditPageGroups;