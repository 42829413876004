const endpoint = "https://webservice.easyappear.it";

const ApiCallPost = async (path, body) => {
  
  try{
    
    const response = 
      await fetch(
        `${endpoint}/${path}`,
        {
          method: "POST",
          body:body
        }
      );
    if(response.status === 200){
      const json = await response.json();
      if(json.response === "ok"){
        return json;    
      }else{
        console.log(json)
        return json.reasons;  
      }
    }

  }catch(_){}
  
  return null;
}

export {ApiCallPost};