import React from 'react';

import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';

function Profile(props) {
  return (
    <DashboardLayout>
      {UserData.name}
    </DashboardLayout>
  );
}

export default Profile;