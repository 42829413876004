import React, { useState } from 'react';

import { useNavigate, useParams} from 'react-router';
import { Row, Breadcrumb, Button, Drawer} from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';

import DashboardLayout from '../DashboardLayout';
import { ThemeMenu, ToolsMenu } from '../../helpers/AppsMenus';
import AppMenuButton from '../AppMenuButton';
import DynamicIcon from '../../helpers/DynamicIcon';
import Breadcrumbs from '../Breadcrumbs';


function Tools(props) {
  

  const navigate = useNavigate();
  const params = useParams();
  const appId = params.id;

  const toolMenu = ToolsMenu;
  const themeMenu = ThemeMenu;


  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={themeMenu}/>
      </div>
      <Row gutter={[10,10]}>
        {
          toolMenu.map((e)=>{
            return(
              <AppMenuButton name={e.value} value={e.value} key={e.key} icon={e.icon} appId={appId} classes="bg-green"/>
            )
          })
        }
      </Row>
    </DashboardLayout>
  );
}

export default Tools;