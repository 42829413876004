import React from 'react';

import {
  CalculatorOutlined,
  ShopOutlined,
  FieldTimeOutlined,
  MailOutlined,
  MessageOutlined,
  FileOutlined,
  CompassOutlined,
  PictureOutlined,
  TeamOutlined,
  HomeOutlined,
  ShakeOutlined,
  FormOutlined,
  InteractionOutlined,
  BellOutlined,
  IdcardOutlined,
  WalletOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
  EuroOutlined,
  CalendarOutlined,
  BookOutlined,
  ContactsOutlined,
  LoginOutlined,
  EditOutlined,
  UserOutlined,
  SolutionOutlined,
  CreditCardOutlined,
  PlusSquareOutlined,
  GiftOutlined,
  FacebookOutlined,
  FileWordOutlined,
  DatabaseOutlined,
  UngroupOutlined,
  AppstoreOutlined,
  QrcodeOutlined,
  PullRequestOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';

function DynamicIcon({icon, className}) {

  return (
    <>
       {
           icon === "CalculatorOutlined"    ? <CalculatorOutlined className={className}/>     :
           icon === "FieldTimeOutlined"     ? <FieldTimeOutlined className={className}/>      :
           icon === "FileOutlined"          ? <FileOutlined className={className}/>           : 
           icon === "CompassOutlined"       ? <CompassOutlined className={className}/>        :
           icon === "PictureOutlined"       ? <PictureOutlined className={className}/>        :
           icon === "MessageOutlined"       ? <MessageOutlined className={className}/>        :
           icon === "TeamOutlined"          ? <TeamOutlined className={className}/>           :
           icon === "MailOutlined"          ? <MailOutlined className={className}/>           :
           icon === "ShopOutlined"          ? <ShopOutlined className={className}/>           :
           icon === "HomeOutlined"          ? <HomeOutlined className={className}/>           :
           icon === "ShakeOutlined"         ? <ShakeOutlined className={className}/>          :
           icon === "FormOutlined"          ? <FormOutlined className={className}/>           :
           icon === "InteractionOutlined"   ? <InteractionOutlined className={className}/>    :
           icon === "BellOutlined"          ? <BellOutlined className={className}/>           : 
           icon === "IdcardOutlined"        ? <IdcardOutlined className={className}/>         : 
           icon === "WalletOutlined"        ? <WalletOutlined className={className}/>         : 
           icon === "InboxOutlined"         ? <InboxOutlined className={className}/>          : 
           icon === "ShoppingCartOutlined"  ? <ShoppingCartOutlined className={className}/>   :  
           icon === "EuroOutlined"          ? <EuroOutlined className={className}/>           :  
           icon === "CalendarOutlined"      ? <CalendarOutlined className={className}/>       :  
           icon === "ContactsOutlined"      ? <ContactsOutlined className={className}/>       :  
           icon === "LoginOutlined"         ? <LoginOutlined className={className}/>          :  
           icon === "EditOutlined"          ? <EditOutlined className={className}/>           :  
           icon === "UserOutlined"          ? <UserOutlined className={className}/>           :  
           icon === "SolutionOutlined"      ? <SolutionOutlined className={className}/>       :  
           icon === "CreditCardOutlined"    ? <CreditCardOutlined className={className}/>     :  
           icon === "PlusSquareOutlined"    ? <PlusSquareOutlined className={className}/>     :  
           icon === "GiftOutlined"          ? <GiftOutlined className={className}/>           :  
           icon === "FacebookOutlined"      ? <FacebookOutlined className={className}/>       :  
           icon === "FileWordOutlined"      ? <FileWordOutlined className={className}/>       :  
           icon === "DatabaseOutlined"      ? <DatabaseOutlined className={className}/>       :
           icon === "UngroupOutlined"       ? <UngroupOutlined className={className}/>        : 
           icon === "AppstoreOutlined"      ? <AppstoreOutlined className={className}/>       : 
           icon === "QrcodeOutlined"        ? <QrcodeOutlined className={className}/>         : 
           icon === "BookOutlined"          ? <BookOutlined className={className}/>           : 
           icon === "QrcodeOutlined"        ? <QrcodeOutlined className={className}/>         : 
           icon === "SearchOutlined"        ? <SearchOutlined className={className}/>         : 
           icon === "UsergroupAddOutlined"  ? <UsergroupAddOutlined className={className}/>   : 
           icon === "QuestionCircleOutlined"? <QuestionCircleOutlined className={className}/> : 
           icon === "PlusOutlined"          ? <PlusOutlined className={className}/>           : 
           icon === "PullRequestOutlined"   ? <PullRequestOutlined className={className}/>    : ""
         }
    </>
  );
}

export default DynamicIcon;