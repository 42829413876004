
import React, { useState } from 'react';

import { BgColorsOutlined, ToolOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Drawer, Row } from 'antd';
import { Animated } from 'react-animated-css';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../helpers/Translations';
import DynamicRoute from '../helpers/DynamicRoute';
import DynamicIcon from '../helpers/DynamicIcon';
import LogoExtended from './LogoExtended';


function Breadcrumbs({menu, navigation}) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  var menu = !menu ? [""] : menu;
return (
  <> 
    <Row 
      gutter={[15,15]}
      className='w-100' 
    >
      <Col 
        xs={{span:24, order:2}}
        sm={{span:18, order:2}}
        md={{span:20, order:2}}
        className="flex align-center"
      >
        {/* sezione con le briciole */}
        <div className='ea-breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={origin}>
                {getLabel("statistiche")}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href={origin + "/apps"}>
                  Apps
              </a>
            </Breadcrumb.Item>
            {navigation?
                navigation.map((e, index)=>{
                  return(
                    <Breadcrumb.Item key={index} className="animated fadeInUp">
                      <>
                        {e.url ?
                          <a onClick={() => navigate(e.url)}>{e.name}</a>
                        :
                          <>{e.name}</>
                        }
                      </>
                    </Breadcrumb.Item>
                  )
                })
              :
                ""
            }
          </Breadcrumb>
        </div>
      </Col>
      <Col
        xs={{span:24, order:1}}
        sm={{span:6, order:2}}
        md={{span:4, order:2}}
        align="end"
      >
        {/* Pulsante per fa comparire il menù nel drawer dell'area strumenti o configura in base a quale menu viene passato come props */}
        {
        menu[0].name == "Pagine" ?
          <Button onClick={showDrawer} type='primary' size="medium" className='bg-blue' icon={<BgColorsOutlined />}>{getLabel("tema")}</Button>
        :
        menu[0].name == "Pos" ?
          <Button onClick={showDrawer} type='primary' size="medium" className='bg-green' icon={<ToolOutlined />}>{getLabel("strumenti")}</Button>
        :
          ""
        }
      </Col>
    </Row>
    
    <Drawer title={<LogoExtended/>} className="ea-right-drawer" placement="right" onClose={onClose} open={open}>
      {
       menu[0].name == "Pagine" ?
          <div className='flex fs-20 fw-5 gap-5 py-10 cursor-pointer transition-01 hover-ml10 hover-primary' onClick={()=> navigate("/theme/22")}>
            <BgColorsOutlined />
            {getLabel("tema")}
          </div>
      :
       menu[0].name == "Pos" ?
          <div className='flex fs-20 fw-5 gap-5 py-10 cursor-pointer transition-01 hover-ml10 hover-primary' onClick={()=> navigate("/tools/22")}>
            <ToolOutlined />
            {getLabel("strumenti")}
          </div>
      :
        ""
      }
      <div className='flex column'>

      {/* Metodo Map per far comparire il menù in base a quale viene passato */}
      {menu[0] ?
          menu.map((e, index) => {
            return(
              <div onClick={() => navigate("/"+e.value+"/"+params.id)} key={index} className='flex fs-18 gap-5 p-10 cursor-pointer transition-01 hover-ml10 hover-primary'>
                <DynamicIcon icon={e.icon}/>
                <div>
                  {getLabel(e.value)}
                </div>
              </div>
            )
          })
        :
          ""
      }
      </div>
    </Drawer>
  </>
  );
}

export default Breadcrumbs;