import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { Row } from 'antd';

import { ThemeMenu, ToolsMenu } from '../../helpers/AppsMenus';
import { getLabel } from '../../helpers/Translations';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import AppMenuButton from '../AppMenuButton';
import Breadcrumbs from '../Breadcrumbs';

function Theme(props) {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [appName, setAppName] = useState("");
  const navigate = useNavigate();
  const themeMenu = ThemeMenu;
  const toolsMenu = ToolsMenu;
  const params = useParams();
  const appId = params.id;

  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("tema")
      },
      {
        name:appName
      },
    ])
  },[appName])


  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={toolsMenu}/>
      </div>
      <Row gutter={[10,10]}> 
        {
          themeMenu.map((e)=>{
            return(
              <AppMenuButton name={e.value} value={e.value} key={e.key} icon={e.icon} appId={appId} classes="bg-blue"/>
            )
          })
        }
      </Row>
    </DashboardLayout>
  );
}

export default Theme;