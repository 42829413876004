import React from 'react';

import { BgColorsOutlined, SettingOutlined, SplitCellsOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router';

import { getLabel } from '../helpers/Translations';

const { Paragraph } = Typography;


function AppCard(props) {

  const app_data = props.app_data;
  const navigate = useNavigate()

  function onClick(url){
    sessionStorage.setItem("__ea_app_token",app_data.token_key);
    sessionStorage.setItem("__ea_app_name",app_data.name);
    sessionStorage.setItem("__ea_app_id",app_data.id);
    if(url) navigate("/"+url+"/"+app_data.id);
    else(navigate("/"+app_data.id));
  }

  return (
    <div className='ea-card h-100 gap-10 align-center w-100 relative'>

      <div className='flex gap-10'>
        {/* Logo dell'app */}
        <div
          className='ea-app-logo ar-1 radius-circle cursor-pointer hover-opacity-09 animated fadeIn' 
          style={{
            backgroundImage:"url(" + app_data.app_icon + ")", 
            backgroundColor:app_data.primary_color
          }}
          onClick={() => onClick("tools")}
          />
  
        {/* informazioni app */}
        <div className='ea-app-info flex column w-100'>
          <div className='fs-18 fw-5 cursor-pointer hover-primary'  onClick={() => onClick("tools")}>
            {app_data.name}
          </div>
          <div className='fs-12 color-grey fw-5'>
            {app_data.company_name}
          </div>
          <div className='fs-12 color-grey'>
            {app_data.email}
          </div>
          <div className='fs-12 color-grey mb-10'>
            {app_data.address + ", " + app_data.city + " " + app_data.zip_code+ " " + app_data.province}
          </div>
          <div className='flex gap-5 w-100'>
            <Paragraph copyable className='fs-12 fw-5 color-grey word-break'>
              {app_data.token_key}
            </Paragraph>
          </div>
        </div>
      </div>
  
      <div className='ea-app-actions mt-10'>
        <Row>
          <Col span={12} xs={{span:12}} sm={{span:6}} md={{span:12}} xl={{span:6}} xxl={{span:6}}  align="center">
            <Button
              className="hover-bg-green w-100 p-0"
              type="text"
              icon={<ToolOutlined className='color-green' />}
              onClick={() => onClick("tools")}
            >
              {getLabel("strumenti")}
            </Button>
          </Col>
          <Col span={12} xs={{span:12}} sm={{span:6}} md={{span:12}} xl={{span:6}} xxl={{span:5}}  align="center">
            <Button
              className="hover-bg-blue w-100 p-0"
              type="text"
              icon={<BgColorsOutlined className='color-blue' />}
              onClick={() => onClick("")}
            >
              {getLabel("tema")}
            </Button>
          </Col>
          <Col span={12} xs={{span:12}} sm={{span:6}} md={{span:12}} xl={{span:6}} xxl={{span:8}}  align="center">
            <Button
              className="hover-bg-primary w-100 p-0"
              type="text"
              icon={<SettingOutlined className='color-primary' />}
              onClick={() => navigate("/"+app_data.id+"/edit")}
            >
              {getLabel("impostazioni")}
            </Button>
          </Col>
          <Col span={12} xs={{span:12}} sm={{span:6}} md={{span:12}} xl={{span:6}} xxl={{span:5}}  align="center">
            <Button
              className="hover-bg-yellow w-100 p-0"
              type="text"
              icon={<SplitCellsOutlined className='color-yellow' />}
              onClick={() => navigate("/duplicate/"+app_data.id)}
            >
              {getLabel("duplica")}
            </Button>
          </Col>
        </Row>
      </div>
      
    </div>
  );
}

export default AppCard;