import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Button,
  Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router';

import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';


function AppEdit(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [loyaltyTypes, setLoyaltyTypes] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [appForms, setAppForms] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [render, setRender] = useState(false);
  const [appName, setAppName] = useState("");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;

  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            `${window.location.pathname}`), 
        getItem(getLabel( "tema"),                'apps/'+appId+'/theme'),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        'apps/'+appId+'/smtp'),
        getItem(          "SMS",                  'apps/'+appId+'/sms'),
        getItem(getLabel( "widget_refer_friend"), 'apps/'+appId+'/refer_friend'),
        getItem(getLabel( "google_login"),        'apps/'+appId+'/google_login'),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;

    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=null", JSON.stringify(body));
      if(res.response === "ok"){

        const appGeneralSettings = res.results[0];

        //setto le multiselezioni
        setLoyaltyPrograms(appGeneralSettings.loyalty_programs);
        setLoyaltyTypes(appGeneralSettings.loyalty_types);
        setLanguages(appGeneralSettings.apps_languages);
        setAppForms(appGeneralSettings.apps_forms);



        //imposto i valori di input già configurati
        form.setFieldsValue(
          {
            "name":appGeneralSettings.name,
            "reference":appGeneralSettings.reference,
            "id_language":appGeneralSettings.id_language,
            "id_app_form":appGeneralSettings.id_app_form,
            "id_app_form_orders":appGeneralSettings.id_app_form_orders,
            "id_loyalty_program":appGeneralSettings.id_loyalty_program,
            "id_loyalty_type":appGeneralSettings.id_loyalty_type,
            "minimum_cart":appGeneralSettings.minimum_cart,
            "maximum_products_sets_order":appGeneralSettings.maximum_products_sets_order,
            "hide_guest_prices":parseInt(appGeneralSettings.hide_guest_prices),
            "allow_partial_payment":parseInt(appGeneralSettings.allow_partial_payment),
            "app_master_coupons":parseInt(appGeneralSettings.app_master_coupons),
            "id_app_android":appGeneralSettings.id_app_android,
            "id_app_ios":appGeneralSettings.id_app_ios,
            "firebase_api_key":appGeneralSettings.firebase_api_key,
            "url_new_order":appGeneralSettings.url_new_order,
            "maximum_shop_products":appGeneralSettings.maximum_shop_products,
            "hide_signup_fields":Object.keys(appGeneralSettings.hide_signup_fields),
            "manual_customers_activation":parseInt(appGeneralSettings.manual_customers_activation),
            "restrict_devices":parseInt(appGeneralSettings.restrict_devices),
            "on_demand_content":parseInt(appGeneralSettings.on_demand_content),
            "expired":parseInt(appGeneralSettings.expired),
            "multy_app":parseInt(appGeneralSettings.multy_app),
            "is_published":parseInt(appGeneralSettings.is_published),
            "enabled":parseInt(appGeneralSettings.enabled),
          }
        )
        setRender(true);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])

  //input di ricerca per gruppi di pagine e sottoapps
  const onChange = (value) => {
  };
  const onSearch = (value) => {
  };

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          {/* menu laterale della modifica */}
          <Col 
            xs={24} 
            sm={24} 
            md={6}
            xl={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          {/* area di input modifiche */}
          <Col  
            xs={24} 
            sm={24} 
            md={18} 
            xl={20}
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("generale")}
              <Divider/>
            </div>

            {/* form di modifica */}
              {render ?
                <Form
                  form={form}
                  labelCol={{ 
                    xs:{
                      span:8
                    },
                    lg:{
                      span: 8 
                    },
                    xxl:{
                      span: 6 
                    }
                  }}
                  wrapperCol={{ 
                    xs:{
                      span:24
                    },
                    lg:{
                      span: 16
                    },
                    xxl:{
                      span: 18
                    }
                  }}
                  layout='horizontal'
                  name="pageSetting"
                  onFinish={onFinish}
                >
                  {/* Nome dell'app */}
                  <Form.Item
                    name='name'
                    label={getLabel("nome")}
                    rules={[
                      {
                        required: true,
                        message:getLabel("campo_obbligatorio")
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>

                  {/*riferimento dell'app */}
                  <Form.Item
                    name='reference'
                    label={getLabel("riferimento")}
                  >
                    <Input/>
                  </Form.Item>

                  {/*lingua dell'app */}
                  <Form.Item
                    name='id_language'
                    label= {getLabel("lingua")}
                    rules={[
                      {
                        required: true,
                        message:getLabel("campo_obbligatorio")
                      },
                    ]}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={languages}
                      />
                  </Form.Item>

                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("moduli_aggiuntivi")}
                    <Divider/>
                  </div>

                  {/*moduli aggiuntivi*/}
                  <Form.Item
                    name='id_app_form'
                    label= {getLabel("modulo_registrazione")}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={appForms}
                      />
                  </Form.Item>

                  <Form.Item
                    name='id_app_form_orders'
                    label= {getLabel("modulo_prodotto")}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={appForms}
                      />
                  </Form.Item>

                  <Form.Item
                    name='id_app_form_products'
                    label= {getLabel("modulo_ordine")}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={appForms}
                      />
                  </Form.Item>

                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("transazione_acquisto")}
                    <Divider/>
                  </div>

                  {/*transazione all'acquisto*/}
                  <Form.Item
                    name='id_loyalty_program'
                    label= {getLabel("campagna")}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={loyaltyPrograms}
                      />
                  </Form.Item>

                  <Form.Item
                    name='id_loyalty_type'
                    label= {getLabel("attivita")}
                  >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={loyaltyTypes}
                      />
                  </Form.Item>

                  {/*ecommerce*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    E-Commerce
                    <Divider/>
                  </div>

                  <Form.Item
                    name='minimum_cart'
                    label={getLabel("minimo")+" "+getLabel("carrello")}
                  >
                    <InputNumber min={0} decimalSeparator=","/>
                  </Form.Item>

                  <Form.Item 
                    name='maximum_products_sets_order' 
                    label={getLabel("prodotti_massimi_ordine_set")}
                    initialValue={0}
                  >
                    <InputNumber min={0}/>
                  </Form.Item>

                  <Form.Item
                      name='hide_guest_prices'
                      valuePropName="checked"
                      label={getLabel("nascondi_prezzi_ospiti")}
                    >
                      <Checkbox>{getLabel("nascondi_prezzi_ospiti")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                      name='allow_partial_payment'
                      valuePropName="checked"
                      label={getLabel("consenti_pagamento_parziale")}
                    >
                      <Checkbox>{getLabel("consenti_pagamento_parziale")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                      name='app_master_coupons'
                      valuePropName="checked"
                      label={getLabel("usa_coupons_master")}
                    >
                      <Checkbox>{getLabel("usa_coupons_master")}</Checkbox>
                  </Form.Item>

                  {/*AppStore*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    App Store
                    <Divider/>
                  </div>
                  <Form.Item
                    name='id_app_android'
                    label="ID Android"
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name='id_app_ios'
                    label="ID iOS"
                  >
                    <Input/>
                  </Form.Item>

                  {/*notifiche*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("apps_devices_notifications")}
                    <Divider/>
                  </div>
                  <Form.Item
                    name='firebase_api_key'
                    label="Firebase API Key"
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name='url_new_order'
                    label={getLabel("url_notification")}
                  >
                    <Input/>
                  </Form.Item>

                  {/*fatturazione*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("billing")}
                    <Divider/>
                  </div>

                  <Form.Item 
                    name='maximum_shop_products' 
                    label={getLabel("prodotti_massimi_catalogo")}
                    initialValue={0}
                  >
                    <InputNumber min={0}/>
                  </Form.Item>

                  {/*nascondi campi di registrazione*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("nascondi_campi_registrazione")}
                    <Divider/>
                  </div>

                  <Form.Item 
                    name='hide_signup_fields' 
                    label={getLabel("nascondi_campi_registrazione")}
                  >
                    <Checkbox.Group 
                      options={[
                        {
                          label:getLabel("genere"),
                          value:"gender",
                        },
                        {
                          label:getLabel("nome"),
                          value:"name",
                        },
                        {
                          label:getLabel("cognome"),
                          value:"surname",
                        },
                        {
                          label:getLabel("data_nascita"),
                          value:"birth_data",
                        },
                        {
                          label:getLabel("cf"),
                          value:"fiscal_code",
                        },
                        {
                          label:getLabel("lotteria"),
                          value:"lottery_code",
                        },
                        {
                          label:getLabel("indirizzo"),
                          value:"address",
                        },
                        { 
                          label:getLabel("telefono"),
                          value:"phone",
                        },
                        {
                          label:getLabel("cellulare"),
                          value:"mobile",
                        },
                        {
                          label:getLabel("termini"),
                          value:"terms_and_conditions",
                        }
                      ]}
                      className="column"
                    />
                  </Form.Item>

                  {/*attivazione clienti*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("apps_customers")}
                    <Divider/>
                  </div>

                  <Form.Item
                    name='manual_customers_activation'
                    valuePropName="checked"
                    label={getLabel("attivazione_manuale")}
                  >
                    <Checkbox>{getLabel("attivazione_manuale")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name='restrict_devices'
                    valuePropName="checked"
                    label={getLabel("vincola_dispositivi")}
                  >
                    <Checkbox>{getLabel("vincola_dispositivi")}</Checkbox>
                  </Form.Item>

                  {/*opzioni*/}      
                  <div className='w-100 px-10 fs-18 fw-5 mt-50'>
                    {getLabel("opzioni")}
                    <Divider/>
                  </div>

                  <Form.Item
                    name='on_demand_content'
                    valuePropName="checked"
                    label={getLabel("scarica_contenuti")}
                  >
                    <Checkbox>{getLabel("scarica_contenuti")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name='expired'
                    valuePropName="checked"
                    label={getLabel("scaduta")}
                  >
                    <Checkbox>{getLabel("scaduta")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name='multy_app'
                    valuePropName="checked"
                    label={getLabel("app_multipla")}
                  >
                    <Checkbox>{getLabel("app_multipla")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name='is_published'
                    valuePropName="checked"
                    label={getLabel("is_published")}
                  >
                    <Checkbox>{getLabel("is_published")}</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name='enabled'
                    valuePropName="checked"
                    label={getLabel("status")}
                  >
                    <Checkbox>{getLabel("enabled")}</Checkbox>
                  </Form.Item>

                  <Divider/>
                  {/*salva o salva e rimani*/}
                  <Form.Item 
                    label=" " 
                    className='save-form'
                  >
                    <div className='flex gap-10 flex-wrap'>
                      <Button type="primary" size="medium" htmlType="submit">
                        {getLabel("salva")}
                      </Button>
                      <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                        {getLabel("salva_rimani")}
                      </Button>
                    </div>
                  </Form.Item>

                </Form>
                :
                <div className='w-100 flex justify-center'>
                  <Spin/>
                </div>
              }
          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEdit;