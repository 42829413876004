import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router';

function PrivateRoutes(props) {

  const accessToken = sessionStorage.getItem("__ea_access_token") ? sessionStorage.getItem("__ea_access_token"):localStorage.getItem("__ea_access_token");
  const [render, setRender] = useState(true);
  const navigation = useNavigate();

  return (
    accessToken ? <Outlet/> : <Navigate to="/login"/>
  );
}

export default PrivateRoutes;