import React from 'react';

import { useNavigate } from 'react-router';

const location = window.location;
const origin = window.location.origin;
const path = window.location.pathname;
const pathLocations = path.split("/");

function DynamicRoute(props) {

  const navigate = useNavigate();

  console.log(origin)
  return (
    <div>
       <a href={origin}>
          {
            pathLocations[1] == "apps_pages" ? "Pagine" : 
            pathLocations[1] == "apps_locations" ? "Sedi": 
            ""
          }
        </a>
    </div>
  );
}

export default DynamicRoute;