import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Upload,
  Form,
  Input,
  Button,
  message,
  Select,
  Divider,
  Popover,
  Spin,
  Popconfirm
} from 'antd';
import { 
  ShoppingCartOutlined,
  PictureOutlined,
  LoadingOutlined, 
  DeleteOutlined, 
  UserOutlined, 
  LockOutlined,
  MoreOutlined, 
  PlusOutlined, 
  MenuOutlined 
} from '@ant-design/icons';
import { SketchPicker } from 'react-color';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';

const props = {
  name: 'file',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      console.log(info.file)
    } else if (info.file.status === 'error') {
    }
  },
};

//funzione per recuperare il base64 dall'immagine con i controlli prima dell'upload
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg/gif' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Puoi caricare solo file PNG,GIF o SVG');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

function AppEditTheme(props) {

  const [selectedKey] = useState(window.location.pathname);
  const [themeSettings, setThemeSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [render, setRender] = useState(false);

  //gestione dei colori
  const [buttonContentColor, setButtonContentColor] = useState("");
  const [navbarTextColor, setNavbarTextColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [textColor, setTextColor] = useState("");
  //gestione dei colori

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [appName, setAppName] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;

  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            appId+"/edit"), 
        getItem(getLabel( "tema"),                `${window.location.pathname}`),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        'apps/'+appId+'/smtp'),
        getItem(          "SMS",                  'apps/'+appId+'/sms'),
        getItem(getLabel( "widget_refer_friend"), 'apps/'+appId+'/refer_friend'),
        getItem(getLabel( "google_login"),        'apps/'+appId+'/google_login'),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=theme", JSON.stringify(body));
     
      if(res.response === "ok"){

        setThemeSettings(res.results[0]);
        var appThemeSettings = res.results[0];
        console.log(appThemeSettings);
        //imposto i valori di imput già configurati

        setPrimaryColor(appThemeSettings.primary_color);
        setSecondaryColor(appThemeSettings.secondary_color);
        setButtonColor(appThemeSettings.button_color);
        setButtonContentColor(appThemeSettings.button_content_color);
        setBackgroundColor(appThemeSettings.background_color);
        setTextColor(appThemeSettings.text_color);
        setNavbarTextColor(appThemeSettings.top_bar_text_color);

        form.setFieldsValue({
          "primary_color":appThemeSettings.primary_color,
          "secondary_color":appThemeSettings.secondary_color,
          "button_content_color":appThemeSettings.button_content_color,
          "background_color":appThemeSettings.background_color,
          "navbar_text_color":appThemeSettings.top_bar_text_color,
          "button_color":appThemeSettings.button_color,
          "text_color":appThemeSettings.text_color,
        })
        setRender(true);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }
  //----------  funzioni della gestione upload ---------- 
  const handleChange = (info) => {
    
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    getBase64(info.file.originFileObj, (url) => {

      setLoading(false);
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('Puoi caricare solamente files JPG e PNG');
      }
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('La foto deve pesare meno di 2Mb');
      }
      if (isJpgOrPng && isLt2M){
         
          setImageUrl(url);
        
      }
    });

  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);

      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
    
  };

  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          <Col 
            xs={24} 
            sm={24} 
            md={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          <Col  
            xs={24} 
            sm={24} 
            md={20} 
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("identita")}
              <Divider/>
            </div>
            {/* modifica form */}
            {render ? 
              <Form
                form={form}
                labelCol={{ 
                  xs:{
                    span:8
                  },
                  lg:{
                    span: 8 
                  },
                  xxl:{
                    span: 6 
                  }
                }}
                wrapperCol={{ 
                  xs:{
                    span:24
                  },
                  md:{
                    span: 10
                  },
                  lg:{
                    span: 10
                  },
                  xxl:{
                    span: 6
                  }
                }}
                layout='horizontal'
                name="pageSetting"
                onFinish={onFinish}
              >
                {/*icona dell'app */}
                <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:8}}
                    xxl={{span:6}}
                    className='h-100'
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>{getLabel("icona")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:16}}
                    xxl={{span:18}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        {!themeSettings.icon ?
                          <Form.Item className='mb-0'>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                onPreview={onPreview}
                              >
                                <div>
                                  {loading ? <LoadingOutlined /> : <PlusOutlined/>}
                                  <div>
                                    Carica
                                  </div>
                                </div>
                              </Upload>
                          </Form.Item>
                        :
                          <div 
                            className='ea-form-image-wrapper'
                            style={{
                              backgroundColor:themeSettings.primary_color
                            }}
                          >
                            <img
                              className='ea-form-image'
                              src={themeSettings.base_url + themeSettings.icon}
                              style={{
                                height: '100%',
                              }}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*logo dell'app */}
                <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:8}}
                    xxl={{span:6}}
                    className='h-100'
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>Logo</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:16}}
                    xxl={{span:18}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        {!themeSettings.logo ?
                          <Form.Item className='mb-0'>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                onPreview={onPreview}
                              >
                                <div>
                                  {loading ? <LoadingOutlined /> : <PlusOutlined/>}
                                  <div>
                                    Carica
                                  </div>
                                </div>
                              </Upload>
                          </Form.Item>
                        :
                          <div className='ea-form-image-wrapper'
                            style={{
                              backgroundColor:themeSettings.primary_color
                            }}
                          >
                            <img
                              className='ea-form-image'
                              src={themeSettings.base_url + themeSettings.logo}
                              style={{
                                height: '100%',
                              }}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*logo orizzontale */}
                <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:8}}
                    xxl={{span:6}}
                    className='h-100'
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>Logo {getLabel("orizzontale")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:16}}
                    xxl={{span:18}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        {!themeSettings.logo_horizontal ?
                          <Form.Item className='mb-0'>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                onPreview={onPreview}
                              >
                                <div>
                                  {loading ? <LoadingOutlined /> : <PlusOutlined/>}
                                  <div>
                                    Carica
                                  </div>
                                </div>
                              </Upload>
                          </Form.Item>
                        :
                          <div 
                            className='ea-form-image-wrapper'
                            style={{
                              background:themeSettings.primary_color
                            }}
                          >
                            <img
                              className='ea-form-image'
                              src={themeSettings.base_url + themeSettings.logo_horizontal}
                              style={{
                                height: '100%',
                              }}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*immagine sfondo bottoni */}
                <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:8}}
                    xxl={{span:6}}
                    className='h-100'
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>{getLabel("immagine_sfondo_bottoni")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:16}}
                    xxl={{span:18}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        {!themeSettings.pattern_image ?
                          <Form.Item className='mb-0'>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                onPreview={onPreview}
                              >
                                <div>
                                  {loading ? <LoadingOutlined /> : <PlusOutlined/>}
                                  <div>
                                    Carica
                                  </div>
                                </div>
                              </Upload>
                          </Form.Item>
                        :
                          <div 
                            className='ea-form-image-wrapper'
                            style={{
                              background:themeSettings.button_color
                            }}
                          >
                            <img
                              className='ea-form-image'
                              src={themeSettings.base_url + themeSettings.pattern_image}
                              style={{
                                height: '100%',
                              }}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                          
                {/* colori */}
                <div className='w-100 px-10 fs-18 fw-5'>
                  {getLabel("colori")}
                  <Divider/>
                </div>

                {/* principale */}
                <Form.Item 
                  label={getLabel("colore_principale")}
                  name="primary_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={primaryColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"primary_color":color.hex})}
                          onChange={(color)=>setPrimaryColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={primaryColor}/>
                      <div className='color-preview' style={{background:primaryColor}}/>
                    </div>
                  </Popover>
                </Form.Item>
                {/* secondario */}
                <Form.Item 
                  label={getLabel("colore_secondario")}
                  name="secondary_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={secondaryColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"seconday_color":color.hex})}
                          onChange={(color)=>setPrimaryColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={secondaryColor}/>
                      <div className='color-preview' style={{background:secondaryColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

                {/* sfondo */}
                <Form.Item 
                  label={getLabel("colore_sfondo")}
                  name="background_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={backgroundColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"background_color":color.hex})}
                          onChange={(color)=>setBackgroundColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={backgroundColor}/>
                      <div className='color-preview' style={{background:backgroundColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

                {/* testo */}
                <Form.Item 
                  label={getLabel("colore_testo")}
                  name="text_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={textColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"text_color":color.hex})}
                          onChange={(color)=>setTextColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={textColor}/>
                      <div className='color-preview' style={{background:textColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

              {/* bottoni */}
                <Form.Item 
                  label={getLabel("colore_bottoni")}
                  name="button_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={buttonColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"button_color":color.hex})}
                          onChange={(color)=>setButtonColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={buttonColor}/>
                      <div className='color-preview' style={{background:buttonColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

              {/* colore contenuto bottoni */}
                <Form.Item 
                  label={getLabel("colore_contenuto_bottoni")}
                  name="button_content_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={buttonContentColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"button_content_color":color.hex})}
                          onChange={(color)=>setButtonContentColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={buttonContentColor}/>
                      <div className='color-preview' style={{background:buttonContentColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

              {/* testo navbar */}
                <Form.Item 
                  label={getLabel("colore_testo_barra")}
                  name="navbar_text_color"
                  required
                >
                  <Popover 
                    placement='topLeft'
                    trigger={"click"} 
                    content={
                        <SketchPicker
                          className='color-picker'
                          color={navbarTextColor}
                          onChangeComplete={(color)=>form.setFieldsValue({"navbar_text_color":color.hex})}
                          onChange={(color)=>setNavbarTextColor(color.hex)}
                        />
                      }
                    >
                    <div className='flex gap-10 cursor-pointer'>
                      <Input value={navbarTextColor}/>
                      <div className='color-preview' style={{background:navbarTextColor}}/>
                    </div>
                  </Popover>
                </Form.Item>

                {/* app preview */}
                <div className='w-100 px-10 fs-18 fw-5'>
                  Preview
                  <Divider/>
                </div>

                <div className='w-100 flex justify-center'>
                  <div className='app-preview-frame'>
                    <div 
                      className='app-preview-navbar' 
                      style={{
                        color:navbarTextColor,
                        background:primaryColor
                      }}
                    >
                      <div className='flex align-center gap-10'>
                        <MenuOutlined />
                        <img src={themeSettings.base_url + themeSettings.logo_horizontal} className='app-preview-logo'/>
                      </div>
                      <MoreOutlined />
                    </div>
                    <div className='app-preview-slider'/>
                    <div 
                      className='app-preview-home-grid'
                      style={{
                        background:backgroundColor
                      }}
                    >
                      <div 
                        className='app-preview-home-button'
                        style={{
                          color:buttonContentColor,
                          background:buttonColor
                        }}
                      >
                        <UserOutlined />
                        <div>Chi Siamo</div>
                      </div>
                      <div 
                        className='app-preview-home-button'
                        style={{
                          color:buttonContentColor,
                          background:buttonColor
                        }}
                      >
                        <ShoppingCartOutlined />
                        <div>Shop</div>
                      </div>
                      <div 
                        className='app-preview-home-button'
                        style={{
                          color:buttonContentColor,
                          background:buttonColor
                        }}
                      >
                        <LockOutlined />
                        <div>Area Personale</div>
                      </div>
                      <div 
                        className='app-preview-home-button'
                        style={{
                          color:buttonContentColor,
                          background:buttonColor
                        }}
                      >
                        <PictureOutlined />
                        <div>Fotogallery</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*salva o salva e rimani*/}
                <Form.Item 
                  label=" " 
                  className='save-form'
                >
                  <div className='flex gap-10 flex-wrap'>
                    <Button type="primary" size="medium" htmlType="submit">
                      {getLabel("salva")}
                    </Button>
                    <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                      {getLabel("salva_rimani")}
                    </Button>
                  </div>
                </Form.Item>
              </Form> 
              :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }

          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEditTheme;