import React, { useEffect, useState } from 'react';

import { 
  Menu,  
  Row, 
  Col,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Spin,
  Button,
} from 'antd';

import { useNavigate, useParams } from 'react-router';
import { getLabel } from '../../helpers/Translations';
import { ToolsMenu } from '../../helpers/AppsMenus';
import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';
import { AppById } from '../../helpers/AppById';
import { ApiCallPost } from '../../Backoffice';
import Breadcrumbs from '../Breadcrumbs';



function AppEditReferFriend(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [loyaltyTypes, setLoyaltyTypes] = useState([]);
  const [couponsList, setCouponsList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [appForms, setAppForms] = useState([]);
  const [appToken, setAppToken] = useState("");
  const [render, setRender] = useState(false);
  const [appName, setAppName] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const appId = params.id;


  const { Option } = Select;
  //definisco la funzione per il menù laterale di modifica
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel( "generale"),            appId+'/edit'), 
        getItem(getLabel( "tema"),                'apps/'+appId+'/theme'),
        getItem(getLabel( "termini"),             'apps/'+appId+'/terms'),
        getItem(getLabel( "socials"),             'apps/'+appId+'/socials'),
        getItem(getLabel( "apps_inboxes"),        'apps/'+appId+'/smtp'),
        getItem(          "SMS",                  'apps/'+appId+'/sms'),
        getItem(getLabel( "widget_refer_friend"), `${window.location.pathname}`),
        getItem(getLabel( "google_login"),        'apps/'+appId+'/google_login'),
      ], 
      'group'),
  ]
  
  //con l'id app dell'url recupero nome e token e li setto 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //recupero i dati delle impostazioni
  useEffect(()=>{
    if(!appToken)return;
    
    const body = {
      token_user:UserData.accessToken,
      token_key:appToken
    };
    
    (async()=>{
      const res = await  ApiCallPost("app?section=referer_friend", JSON.stringify(body));
      if(res.response === "ok"){

        const setting = res.results[0];

        //setto le multiselezioni
        setLoyaltyPrograms(setting.loyalty_programs);
        setLoyaltyTypes(setting.loyalty_types);
        setCouponsList(setting.shops_coupons);
        setAppForms(setting.apps_forms);

        console.log(setting);

        //imposto i valori di imput già configurati
        form.setFieldsValue(
          {
            refer_friend_customer_id_coupon:setting.refer_friend_customer_id_coupon,
            refer_friend_id_loyalty_program:setting.refer_friend_id_loyalty_program,
            refer_friend_parent_id_coupon:setting.refer_friend_parent_id_coupon,
            refer_friend_customer_amount:setting.refer_friend_customer_amount,
            refer_friend_id_loyalty_type:setting.refer_friend_id_loyalty_type,
            refer_friend_parent_amount:setting.refer_friend_parent_amount,
            refer_friend_enabled:setting.refer_friend_enabled,
            refer_friend_type:setting.refer_friend_type,
          }
        )
        setRender(true);
      }else{
        console.log(res)
      }
    })()
    
  },[appToken])

  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:getLabel("impostazioni")
      },
      {
        name:appName
      },
    ])
  },[appName])

  //input di ricerca per gruppi di pagine e sottoapps
  const onChange = (value) => {
  };
  const onSearch = (value) => {
  };

  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }


  return (
    <DashboardLayout>
      <div className='ea-card mb-20'>
        <Breadcrumbs menu={ToolsMenu} navigation={breadcrumbs}/>
      </div>
      <div className='ea-card'>
        <Row>
          <Col 
            xs={24} 
            sm={24} 
            md={4}
          >
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          <Col  
            xs={24} 
            sm={24} 
            md={20} 
            className="p-10"
          >
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("widget_refer_friend")}
              <Divider/>
            </div>
            {/* modifica form */}
            
            {render ?
            <Form
              form={form}
              labelCol={{ 
                xs:{
                  span:8
                },
                lg:{
                  span: 8 
                },
                xxl:{
                  span: 6 
                }
              }}
              wrapperCol={{ 
                xs:{
                  span:24
                },
                lg:{
                  span: 16
                },
                xxl:{
                  span: 18
                }
              }}
              layout='horizontal'
              name="pageSetting"
              onFinish={onFinish}
            >

              {/* tipo porta un amico */}
              <Form.Item
                name='refer_friend_type'
                label= {getLabel("type")}
              >
                <Select
                    optionFilterProp="children"
                    onChange={onChange}
                    options={[
                      {
                        "value":"fidelity",
                        "label":"Fidelity"
                      },
                      {
                        "value":"coupon",
                        "label":"Coupon"
                      }
                    ]}
                  />
              </Form.Item>
              {/*  tipo porta un amico */}


              <div className='w-100 px-10 fs-18 fw-5'>
                Fidelity
                <Divider/>
              </div>

              <Form.Item
                name='refer_friend_id_loyalty_program'
                label= {getLabel("campagna")}
              >
                <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={loyaltyPrograms}
                  />
              </Form.Item>

              <Form.Item
                name='refer_friend_id_loyalty_type'
                label= {getLabel("attivita")}
              >
                <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={loyaltyTypes}
                  />
              </Form.Item>

              <Form.Item
                name='refer_friend_parent_amount'
                label={getLabel("importo")+" "+getLabel("cliente")+" "+getLabel("promotore")}
              >
                <InputNumber min={0} decimalSeparator=","/>
              </Form.Item>

              <Form.Item
                name='refer_friend_customer_amount'
                label={getLabel("importo")+" "+getLabel("cliente")+" "+getLabel("invitato")}
              >
                <InputNumber min={0} decimalSeparator=","/>
              </Form.Item>

              <div className='w-100 px-10 fs-18 fw-5'>
                Coupon
                <Divider/>
              </div>

              <Form.Item
                name='refer_friend_parent_id_coupon'
                label={"Coupon "+getLabel("cliente")+" "+getLabel("promotore")}
              >
                <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={couponsList}
                  />
              </Form.Item>

              <Form.Item
                name='refer_friend_customer_id_coupon'
                label={"Coupon "+getLabel("cliente")+" "+getLabel("invitato")}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={couponsList}
                />
              </Form.Item>


              <div className='w-100 px-10 fs-18 fw-5'>
                {getLabel("opzioni")}
                <Divider/>
              </div>

              <Form.Item
                  name='refer_friend_enabled'
                  valuePropName="checked"
                  label={getLabel("enabled")}
                >
                  <Checkbox>{getLabel("enabled")}</Checkbox>
              </Form.Item>

              <Divider/>
              {/*salva o salva e rimani*/}
              <Form.Item 
                label=" " 
                className='save-form'
              >
                <div className='flex gap-10 flex-wrap'>
                  <Button type="primary" size="medium" htmlType="submit">
                    {getLabel("salva")}
                  </Button>
                  <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                    {getLabel("salva_rimani")}
                  </Button>
                </div>
              </Form.Item>

            </Form>
            :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }

          </Col>
        </Row>
      </div> 
    </DashboardLayout>
  );
}

export default AppEditReferFriend;