import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Input, Collapse,TreeSelect,DatePicker, Button, Form } from 'antd';
import { FilterOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getLabel } from '../helpers/Translations';
import { useNavigate } from 'react-router';

const { Panel } = Collapse;
const { Search } = Input;
const { RangePicker } = DatePicker;

// funzione per ricavare un oggetto da un insieme di quey params
function getQueryParams(search) {
  const queryString = (search||window.location.search).replace('?', '');
  return queryString.split('&').reduce((prev, cur) => {
      const [key, value] = cur.split('=');
      prev[key] = decodeURI(value);
      return prev;
  }, {});
}

// funzione per vedere se un oggetto è vuoto
function isObjEmpty (obj) {
  return Object.keys(obj).length === 0;
}

// inizializzo l'ogetto vuoto che conterrà tutti i query params, dall'url o selezionati dai filtri 
//ATTENZIONE ALLE CHIAVI DEI FILTRI CHE DANNO IL NOME AI QUERY PARAMS e devono sempre combaciare tutti

var queryParams = window.location.search ? getQueryParams(window.location.search) : {};


function SearchFilter(props) {

  const [showFilters, setShowFilters] = useState(isObjEmpty(queryParams)? "" : "filters")
  const [filterIcon, setFilterIcon] = useState();
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState();
  
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //imposto i filtri se ci sono già 
  useEffect(()=>{
    //se ci sono filtri nell'oggetto quey params, setto i rispettivi valori nei vari input presenti nel form che contiene tutti i filtri
    // ogni input del form filtri si dovrà chiamare come la chiave del query params (e.key)
    if(!isObjEmpty(queryParams)){
      //setto i valori del form con l'oggetto recuperato dai query params
      form.setFieldsValue(queryParams);
      setFilterIcon(true);
    }else{
      setFilterIcon(false);
    }
  },[])

  //recupero dell'url dei filtri
  function activeFilter(){
    var queryString = "";
  
    for (const i in queryParams) {
      queryString += i + "=" + queryParams[i] + "&"
    }
    //accodo al nuovo quey params eventuali parametri pre-esistenti dall'attuale url (che siano di ricerca o filtri)
    queryString = queryString.toString().slice(0, -1);
   
    window.open(props.endpoint + "?" + queryString, "_self")

  }

  //gestisco il parametro di ricerca, lo includo nell'ogetto filters e richiamo la funzione attiva filtri al click del cerca
  function search(e){
    queryParams.search = e;
    activeFilter();
  }
  function onSearchChange(e){
    queryParams.search = e.target.value;
  }

  //recupero il json dei filtri dai props
  const filterJson = props.filterJson;

  //reset dei filtri
  function resetFilter(){
    window.open(props.endpoint, "_self");
  }

  //click sui filtri
  function onPanelClick(){
    setFilterIcon(filterIcon === true ? false : true);
  }
  //struttura per l'header del collaspe che contiene ricerca ed icona filtri
  const genExtra = () => (
    <div className='flex gap-10 w-100'>
      {/* questa funzione stopPagination blocca l'apertura del panel cliccando sulla ricerca */}
      <div onClick={(event) => {event.stopPropagation();}} className="w-100">
        <Form.Item name="search" className='m-0'>
          <Search loading={loading} allowClear onSearch={search} onChange={onSearchChange}/> 
        </Form.Item>
      </div>
      {/* icona del filtro con + e - */}
      <div className='ea-filter-toggle flex align-center'>
        <FilterOutlined className='fs-18'/>
        <div className='flex relative'>
          {filterIcon ?
            <MinusOutlined/>
          :
            <PlusOutlined/>
          }
        </div>
      </div>
   </div>
  );

  return (
    <div>
      <Form form={form}>
        <Collapse defaultActiveKey={showFilters} className="ea-filters-collapse style-hidden ea-card" onChange={onPanelClick}>
          <Panel key="filters" showArrow={false} extra={genExtra()}>
            <div className='ea-filters-area flex flex-wrap gap-10'>
              {filterJson?
                  filterJson.map((e)=>{
                    return(
                        e.filter === "TreeSelect" ? 
                          <Form.Item 
                            name={e.key} 
                            key={e.key} 
                            label={getLabel(e.filter_name)} 
                            className="m-0"
                          >
                            <TreeSelect
                              showSearch
                              filterTreeNode={(search, item) => {
                                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                              }}
                              value={value}
                              className="ea-filter ea-filter-select"
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              placeholder={getLabel(e.filter_name)}
                              allowClear
                              onChange={(value)=>{
                                //aggiungo all'ogetto filtri la chiave del filtro e il valore selezoinato
                                //sostituendolo se già presente
                                queryParams[e.key] = value;
                              }}
                              treeData={e.data}
                            /> 
                          </Form.Item>
                        : 
                        e.filter === "Input" ? 
                          <Form.Item 
                            name={e.key} 
                            key={e.key} 
                            label={getLabel(e.filter_name)} 
                            className="m-0"
                          >
                            <Input 
                              className="ea-filter ea-filter-input"
                              placeholder={getLabel(e.filter_name)}
                            /> 
                          </Form.Item>
                        : 
                        e.filter === "RangePicker" ? 
                          <Form.Item  
                            name={e.key} 
                            key={e.key} 
                            label={getLabel(e.filter_name)} 
                            className="m-0"
                          >
                            <RangePicker
                              placeholder={["Inizio "+getLabel(e.filter_name),"Fine "+getLabel(e.filter_name)]}
                              className='ea-filter ea-filter-range-date'
                              showTime
                              format="YYYY/MM/DD HH:mm:ss"
                              onChange={(dates, dateStrings)=>{
                                if (dates) {
                                  console.log(dates)
                                } else {
                                }
                              }}
                              key={e.key}
                            /> 
                          </Form.Item>
                        :  
                        e.filter === "DatePicker" ? 
                          <Form.Item 
                            name={e.key} 
                            key={e.key} 
                            label={getLabel(e.filter_name)} 
                            className="m-0"
                          > 
                            <DatePicker
                              placeholder={getLabel(e.filter_name)} 
                              className='ea-filter ea-filter-date'
                            /> 
                          </Form.Item>
                        :
                          ""
                    )
                  })
                :
                  ""
              }
            </div>
            <div className='flex gap-10 mt-10'>
              <Button type="primary" onClick={activeFilter}>{getLabel("filtra")}</Button>
              {!isObjEmpty(queryParams) ? 
                <Button type='primary' danger onClick={resetFilter}>{getLabel("reset_filtri")}</Button>
              :
                ""
              }
            </div>
          </Panel>
        </Collapse>
      </Form>
    </div>  
  );
}

export default SearchFilter;