const Generic = [
  {
    "name":"Sedi",
    "value":"sedi",
    "icon":"CompassOutlined"
  },
  {
    "name":"Contatti",
    "value":"contatti",
    "icon":"ContactsOutlined"
  },
  {
    "name":"Notifiche",
    "value":"notifiche",
    "icon":"BellOutlined"
  },
  {
    "name":"Facebook",
    "value":"facebook",
    "icon":"FacebookOutlined"
  },
  {
    "name":"Wordpress",
    "value":"wordpress",
    "icon":"FileWordOutlined"
  },
  {
    "name":"Cerca",
    "value":"search",
    "icon":"SearchOutlined"
  },
  {
    "name":"Riepilogo Quiz",
    "value":"quizzes_summary",
    "icon":"QuestionCircleOutlined"
  },
];
const Ecommerce = [
  {
    "name":"Catalogo",
    "value":"catalogo",
    "icon":"DatabaseOutlined"
  },
  {
    "name":"Ecommerce",
    "value":"shop",
    "icon":"ShoppingCartOutlined"
  },
  {
    "name":"Ecommerce Verticale",
    "value":"shopbusiness",
    "icon":"UngroupOutlined"
  },
  {
    "name":"Ecommerce Business",
    "value":"categoriesshop",
    "icon":"ShopOutlined"
  },
  {
    "name":"Ecommerce Categorie",
    "value":"categoriesshop",
    "icon":"AppstoreOutlined"
  },
  {
    "name":"Carrello",
    "value":"cart",
    "icon":"ShoppingCartOutlined"
  },
  {
    "name":"Ordini",
    "value":"ordini",
    "icon":"InboxOutlined"
  },
  {
    "name":"Ordini Business",
    "value":"ordersbusiness",
    "icon":"InboxOutlined",
    "secondIcon":"ShopOutlined"
  },
];
const Fidelity = [
  {
    "name":"Fidelity",
    "value":"fidelity",
    "icon":"CreditCardOutlined"
  },
  {
    "name":"Fidelity Plus",
    "value":"fidelity_plus",
    "icon":"CreditCardOutlined",
    "secondIcon":"PlusOutlined"
  },
  {
    "name":"Coupons",
    "value":"coupon",
    "icon":"GiftOutlined"
  },
  {
    "name":"Porta un Amico",
    "value":"refer_friend",
    "icon":"UsergroupAddOutlined"
  }
];
const Selforder = [
  {
    "name":"Lista Set",
    "value":"set_list",
    "icon":"BookOutlined"
  },
  {
    "name":"Ecommerce Lista Set",
    "value":"set_list_shop",
    "icon":"ShoppingCartOutlined",
    "secondIcon":"BookOutlined"
  },
  {
    "name":"Ordini Lista Set",
    "value":"set_list_orders",
    "icon":"InboxOutlined",
    "secondIcon":"BookOutlined"
  }
];
const Account = [
  {
    "name":"Accedi",
    "value":"accedi",
    "icon":"LoginOutlined"
  },
  {
    "name":"Registrati",
    "value":"registrati",
    "icon":"EditOutlined"
  },
  {
    "name":"Profilo",
    "value":"profilo",
    "icon":"UserOutlined"
  },
  {
    "name":"Account",
    "value":"account",
    "icon":"SolutionOutlined"
  }
];
const Rental = [
  {
    "name":"Rental",
    "value":"noleggio",
    "icon":"FieldTimeOutlined"
  },
  {
    "name":"Carrello Noleggio",
    "value":"rental_cart",
    "icon":"ShoppingCartOutlined",
    "secondIcon":"FieldTimeOutlined"
  },
  {
    "name":"Ordini Noleggio",
    "value":"orders_rental",
    "icon":"InboxOutlined",
    "secondIcon":"FieldTimeOutlined"
  }
];

export  {Generic, Ecommerce, Fidelity, Selforder, Account, Rental};