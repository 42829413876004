import React, { useEffect, useState } from 'react';

import { UserData } from '../../helpers/UserData';
import DashboardLayout from '../DashboardLayout';

function Dashboard(props) {

  const accessToken = sessionStorage.getItem("__ea_access_token") ? sessionStorage.getItem("__ea_access_token"):localStorage.getItem("__ea_access_token");

  return (
    <DashboardLayout>
      <section className='ea-area-dashboard'>
        Dashboard
      </section>
    </DashboardLayout>
  );
}

export default Dashboard;