const accessToken = sessionStorage.getItem("__ea_access_token") ? sessionStorage.getItem("__ea_access_token"):localStorage.getItem("__ea_access_token");
const userSurname =  sessionStorage.getItem("__ea_user_surname") ? sessionStorage.getItem("__ea_user_surname"):localStorage.getItem("__ea_user_surname");
const userName =  sessionStorage.getItem("__ea_user_name") ? sessionStorage.getItem("__ea_user_name"):localStorage.getItem("__ea_user_name");
const appToken =  sessionStorage.getItem("__ea_app_token") ? sessionStorage.getItem("__ea_app_token"):localStorage.getItem("__ea_app_token");
const appName =  sessionStorage.getItem("__ea_app_name") ? sessionStorage.getItem("__ea_app_name"):localStorage.getItem("__ea_app_name");
const appId =  sessionStorage.getItem("__ea_app_id") ? sessionStorage.getItem("__ea_app_id"):localStorage.getItem("__ea_app_id");

const UserData = {
  accessToken : accessToken,
  surname : userSurname,
  appToken: appToken,
  appName: appName,
  name : userName,
  appId : appId,
}

export {UserData};