import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import JoditEditor from 'jodit-react';
import { 
  ConfigProvider,
  InputNumber,
  Popconfirm,
  Checkbox,
  Divider,
  message,
  Tooltip,
  Upload,
  Select,
  Button,
  Modal,
  Radio, 
  Input,
  Menu, 
  Form,
  Spin,
  Col,
  Row,
  Tabs, 
} from 'antd';
import { 
  QuestionCircleOutlined, 
  AppstoreAddOutlined,
  BgColorsOutlined, 
  PictureOutlined,
  LoadingOutlined, 
  UploadOutlined, 
  DeleteOutlined, 
  PlusOutlined,
  EditOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
  CompassOutlined,
  FileOutlined,
  UserOutlined,
  ShoppingCartOutlined, 
} from '@ant-design/icons';

import { Generic, Ecommerce, Fidelity, Selforder, Account, Rental } from '../../../../helpers/WidgetList';
import { getLabel, myLang } from '../../../../helpers/Translations';
import { ToolsMenu } from '../../../../helpers/AppsMenus';
import DynamicIcon from '../../../../helpers/DynamicIcon';
import { UserData } from '../../../../helpers/UserData';
import DashboardLayout from '../../../DashboardLayout';
import { ApiCallPost } from '../../../../Backoffice';
import Breadcrumbs from '../../../Breadcrumbs';

const { Option } = Select;

//props del componente upload icona
const props = {
  name: 'page_icon',
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file caricato con successo`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} caricamento fallito.`);
    }
  },
};
//funzione per recuperare il base64 dall'immagine con i controlli prima dell'upload
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg/gif' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Puoi caricare solo file PNG,GIF o SVG');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
//definiamo la navigazione da passare al componente briciole
const breadcrumbs = []

//definisco la funzione per il menù laterale di modifica
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}


function ThemeEditPage(props) {

  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [showAppContentWidget, setShowAppContentWidget] = useState(false);
  const [loadWidget, setLoadWidget] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [pageWidget, setPageWidget] = useState("");
  const [pageGroup, setPageGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [render, setRender] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState(null);
  const [form] = Form.useForm();

  const editor = useRef(null);
  const params = useParams();
  const pageId = params.pageId;
  const navigate = useNavigate();

  //definisco le etichette ti navigazione del menù laterale
  const items = [
    getItem(
      'Modifica', 
      'mdf', 
      null, 
      [
        getItem(getLabel("generale"), `${window.location.pathname}`), 
        getItem(getLabel("gruppi_clienti"), `apps_pages_customers_groups/${pageId}/edit`),
        getItem('Beacons', `apps_pages_beacons/${pageId}`),
      ], 
      'group'),
  ]
  
  // ----------  recupero i dati della pagina ---------- 
  useEffect(()=>{

    if(!UserData.appToken) return;

    setRender(false);
    
    const body = {
      token_user:UserData.accessToken,
      token_key:UserData.appToken,
    };


    (async()=>{
      const res = await  ApiCallPost("page?page_id="+pageId, JSON.stringify(body));
      
      if(res === "token_user_not_present"){
        console.log(res);
      }if(res === "required_fields_missing"){
        console.log(res);
      }else{

        console.log(res);
        var data = res.results[0];

        var groups = [...res.groups];
        groups.forEach(e => {
          e.label = e.name;
          e.value = e.id
          delete e.updated_at
          delete e.created_at
          delete e.id_company
          delete e.id_parent
          delete e.enabled
          delete e.id_app
          delete e.name
          delete e.id
        });
        form.setFieldsValue(
          {
            media_button_background:parseInt(data.media_button_background),
            id_app_page_group:data.id_app_page_group,
            not_logged:parseInt(data.not_logged),
            in_menu:parseInt(data.in_menu),
            in_home:parseInt(data.in_home),
            enabled:parseInt(data.enabled),
            logged:parseInt(data.logged),
            reference:data.reference,
            subtitle:data.subtitle,
            position:data.position,
            widget:data.widget,
            title:data.title,
            body:data.body,
            url:data.url,
          }
        );
        setPageWidget(data.widget);
        setPageData(data);
        setBody(data.body);
        setPageGroup(groups);


        setRender(true);

      }

    })()

  },[UserData.accessToken])

  // ----------  recupero i dati dei widget all'apertura dei widget---------- 
  function openWidget(){
    
    setLoadWidget(true);

    const body = {
      token_user:UserData.accessToken,
      token_key:UserData.appToken,
    };


    (async()=>{
      const res = await  ApiCallPost("dynamic_widgets", JSON.stringify(body));
      
      if(res === "token_user_not_present"){
        console.log(res);
      }if(res === "required_fields_missing"){
        console.log(res);
      }else{
        console.log(res);
        setWidgetList(res.results);
        setOpen(true);
        setLoadWidget(false);

      }
    })()
  }

  //---------- modal ed input per la selezione dei widget ---------- 
  const useResetFormOnCloseModal = ({ form, open }) => {
    const prevOpenRef = useRef();
    useEffect(() => {
      prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
      if (!open && prevOpen) {
        form.resetFields();
      }
    }, [form, prevOpen, open]);
  };
  const ModalForm = () => {
  useResetFormOnCloseModal({
    form,
    open,
  });
  const chooseWidget = (e) => {
    console.log(e);
    setOpen(false);
    setPageWidget(e);
    form.setFieldsValue({
      widget:e
    })
  }
    // ------------ oggetto modal struttura ------------
    return (
      <Modal title="Scegli il tuo Widget" width={1000} open={open} onCancel={() => setOpen(false)} footer={false}>
        <Tabs
          defaultActiveKey="widget"
          items={["widget", "app_contents"].map((e, i) => {
            return {
              label: (
                <span>
                  {getLabel(e)}
                </span>
              ),
              key: e,
              children: (e == "widget" ?

                // ------------ form con i widget app build in ------------
                <Radio.Group buttonStyle="solid" onChange={(e) => chooseWidget(e.target.value)}>
  
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        {getLabel("generici")}
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Widget contenenti alcune delle funzioni accessorie dell'App"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Generic.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        Account
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Gruppo di widget utili a far registrare ed accede i tuoi clienti in App"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Account.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        Fidelity
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Gruppo dei widget utili al funzionamento dell'ambiente di fidelizzazione dell'app"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Fidelity.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        Ecommerce
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Tutti i widget utili a vendere prodotti in App"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Ecommerce.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        Self-Order
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Insieme di widget per permettere di mostrare i menù e far ordinare il tuo cliente (anche in webapp)"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Selforder.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  <Row gutter={[10,10]} className="mb-20">
                    {/* map dei widget della fidelity */}
                    <div className='flex gap-5 w-100 fs-18 fw-5'>
                      <div>
                        Rental
                      </div>
                      <Tooltip 
                        className="cursor-pointer color-primary ea-chart-tooltip fs-18" 
                        title="Widget per mostrare i prodotti di categoria noleggio ed i rispettivi ordini in corso di noleggio"
                      >
                        <QuestionCircleOutlined className=''/>
                      </Tooltip>
                    </div>
                    {
                      Rental.map(e =>{
                        return(
                          <Col
                            className='h-100'
                            xs={{span:12}}
                            sm={{span:8}}
                            md={{span:4}}
                            key={e.value}
                          >
                            <Radio.Button value={e.value} className='page-widget-selector h-100 w-100'>
                              <div className='flex column align-center'>
                                  <div className='relative'>
                                  <DynamicIcon icon={e.icon} className="widget-primary-icon"/>
                                  <DynamicIcon icon={e.secondIcon || ""} className="widget-secodnary-icon"/>
                                  </div>
                                  <div className='widget-selector-label'>
                                    {getLabel(e.value)}
                                  </div>
                              </div>
                            </Radio.Button>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Radio.Group>
                :
                // ------------ form con i contenuti app dinamici ------------
                <>
                    <Divider>
                      <PictureOutlined/> {getLabel("album")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("album:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.album}
                    />

                    <Divider>
                      <CalendarOutlined/> {getLabel("calendar")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("calendar:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.calendar}
                    />

                    <Divider>
                      <UnorderedListOutlined/> {getLabel("catalogo")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("catalogo:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.catalogo}
                    />

                    <Divider>
                      <EditOutlined/> {getLabel("form")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("form:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.form}
                    />

                    <Divider>
                      <PictureOutlined/> {getLabel("gallery")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("gallery:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.gallery}
                    />

                    <Divider>
                      <CompassOutlined/> {getLabel("location")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("location:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.location}
                    />

                    <Divider>
                      <FileOutlined/> {getLabel("pages")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("pages:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.pages}
                    />

                    <Divider>
                      <UserOutlined/> {getLabel("queue")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("queue:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.queue}
                    />

                    <Divider>
                      <ShoppingCartOutlined/> {getLabel("shop")}
                    </Divider>
                    <Select
                      showSearch
                      className='w-100'
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={(e) => chooseWidget("shop:" + e)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={widgetList.shop}
                    />
                </>
              ),
            };
          })}
        />
      </Modal>
    );
  };

  //----------  funzioni della gestione modal e widget ---------- 

  const hideUserModal = () => {
    setOpen(false);
  };
  const onSelectedWidget = (values) => {
  };
  //----------  input di ricerca per gruppi di pagine e sottoapps ---------- 
  const onChange = (value) => {
  };
  const onSearch = (value) => {
  };



  //funzione che viene eseguita al salva
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  //funzione che viene eseguita al click sul menù laterale
  const onClick = (values) =>{
    if(values.key === window.location.pathname) return;
    navigate("/"+values.key);
  }

  
  //quando arrivano i dati delle briciole le setto
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:UserData.appName,
        url:"/"+UserData.appId
      },
      {
        name:getLabel("apps_pages"),
        url:"/apps_pages/"+UserData.appId
      },
      {
        name:pageData.title
      },
    ])
  },[pageData])

  //----------  funzioni della gestione upload ---------- 
  const handleChange = (info) => {
    
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    getBase64(info.file.originFileObj, (url) => {

      setLoading(false);
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('Puoi caricare solamente files JPG e PNG');
      }
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('La foto deve pesare meno di 2Mb');
      }
      if (isJpgOrPng && isLt2M){
         
          setImageUrl(url);
        
      }
    });

  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);

      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
    
  };


  return (
    <DashboardLayout>
      <Breadcrumbs navigation={breadcrumbs}/>
      <div className='ea-card mt-10'>
        <Row>
          {/* menu laterale della modifica */}
          <Col xs={{span:24}} sm={{span:24}} md={{span:4}}>
            <Menu
              onClick={onClick}
              defaultSelectedKeys={selectedKey}
              mode="inline"
              className='h-100'
              items={items}
            />
          </Col>
          {/* area di input modifiche */}
          <Col  xs={{span:24}} sm={{span:24}} md={{span:20}} className="p-10">
            <div className='w-100 px-10 fs-18 fw-5'>
              {getLabel("generale")}
              <Divider/>
            </div>

            {/* inizio del form della modifica pagina */}
            {render ?
              <Form
                form={form}
                labelCol={{ 
                  xs:{
                    span:8
                  },
                  lg:{
                    span: 6 
                  },
                  xxl:{
                    span: 4 
                  }
                }}
                wrapperCol={{ 
                  xs:{
                    span:24
                  },
                  lg:{
                    span: 18
                  },
                  xxl:{
                    span: 20
                  }
                }}
                layout='horizontal'
                name="pageSetting"
                onFinish={onFinish}
              >
                {/*titolo della pagina */}
                <Form.Item
                  name='title'
                  label={getLabel("titolo")}
                  tooltip={getLabel("tooltip_title")}
                  rules={[
                    {
                      required: true,
                      message:"Questo campo è obbligatorio"
                    },
                  ]}
                >
                  <Input placeholder='Il titolo darà il nome al pulsante e alla pagina'/>
                </Form.Item>
                {/*sottotitolo della pagina */}
                <Form.Item
                  name='subtitle'
                  label={getLabel("sottotitolo")}
                  tooltip={getLabel("tooltip_subtitle")}
                >
                  <Input placeholder='Il sottotiolo comparirà prima del corpo della pagina'/>
                </Form.Item>
                {/*riferimento della pagina */}
                <Form.Item
                  name='reference'
                  label={getLabel("riferimento")}
                >
                  <Input/>
                </Form.Item>
                {/*copertina della pagina */}
                <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:6}}
                    xxl={{span:4}}
                    className='h-100'
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>{getLabel("copertina")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:18}}
                    xxl={{span:20}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        {!pageData.media ?
                          <Form.Item className='mb-0'>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                onPreview={onPreview}
                              >
                                <div>
                                  {loading ? <LoadingOutlined /> : <PlusOutlined/>}
                                  <div>
                                    Carica
                                  </div>
                                </div>
                              </Upload>
                          </Form.Item>
                        :
                          <div className='ea-form-image-wrapper'>
                            <img
                              className='ea-form-image'
                              src={pageData.base_url + pageData.media}
                              style={{
                                height: '100%',
                              }}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                {/*copertina come sfondo della pagina */}
                <Form.Item
                  valuePropName="checked"
                  name='media_button_background'
                  label={getLabel("come_sfondo_bottone")}
                  tooltip={getLabel("tooltip_as_background")}
                >
                  <Checkbox>{getLabel("usa_come_sfondo_bottone")}</Checkbox> 
                </Form.Item>
                {/*editor corpo della pagina */}
                <Form.Item
                  name='body'
                  label={getLabel("corpo")}
                >
                  <JoditEditor
                    ref={editor}
                    value={body}
                    onChange={
                      newContent => {
                        form.setFieldsValue({
                          body:newContent
                        })
                      }
                    }
                  />
                  <Input className='hide'/>
                </Form.Item>
                {/*gruppo di appartenenza della pagina */}
                <Form.Item
                  name='id_app_page_group'
                  label={getLabel("gruppo_appartenenza")}
                  tooltip={getLabel("tooltip_gruppo")}
                >
                  <Select
                    showSearch
                    placeholder={getLabel("cerca_seleziona")}
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={pageGroup}
                  />
                </Form.Item>
                <div className='w-100 px-10 fs-18 fw-5'>
                  {getLabel("icona")}
                  <Divider/>
                </div>
                {/*selezione dell'icona o caricamento da locale */}
                {pageData.icon ?
                  <Row gutter={{ xs: 0, sm: 15}} className='mb-20' align="middle">
                    <Col
                      xs={{span:8}}
                      sm={{span:8}}
                      lg={{span:6}}
                      xxl={{span:4}}
                      className='h-100'
                    >
                      <Row align={{sm:"end", xs:"start"}}>
                        <div className='ant-form-item-label'>
                          <label>{getLabel("icona")}:</label>
                        </div>
                      </Row>
                    </Col>
                    <Col
                      xs={{span:24}}
                      sm={{span:16}}
                      lg={{span:18}}
                      xxl={{span:20}}
                    >
                      <Row>
                        <Col 
                        xs={{span:24}}
                        sm={{span:8}}
                        lg={{span:6}}
                        xl={{span:5}}
                        xxl={{span:3}}
                        >
                          <div className='ea-form-image-wrapper icon' style={{backgroundColor:sessionStorage.getItem("__ea_app_button_color")}}>
                            <img
                              className='ea-form-image'
                              src={pageData.base_url + pageData.icon}
                            />
                            <Popconfirm
                              placement="bottom"
                              title="Vuoi eliminare questa foto?"
                              okText="Si"
                              cancelText="No"
                            >
                              <div className='delete-form-image cursor-pointer flex justify-center align-center'>
                                <DeleteOutlined/>
                              </div>
                            </Popconfirm>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                :
                  <Form.Item
                    name='icon'
                    label={getLabel("icona")}
                  >
                    <div className='flex gap-10 flex-wrap'>
                      <Upload {...props}>
                        <Button icon={<UploadOutlined />}>{getLabel("carica_da_pc")}</Button>
                      </Upload>
                      <Button icon={<BgColorsOutlined />}>{getLabel("sfoglia_dalla_raccolta")}</Button>
                    </div>
                    <div className='mt-10 fs-12 color-grey'>
                      PNG,GIF,SVG | Min.20x20\Max.250x250 | 100KB
                    </div>
                  </Form.Item>
                }
                <div className='w-100 px-10 fs-18 fw-5'>
                  {getLabel("opzioni")}
                  <Divider/>
                </div>

                {/*form nestato nella modal per ottenere il widget della pagina */}
                  <Form.Item label="Widget" name='widget'>
                    <Button htmlType="button" loading={loadWidget} onClick={openWidget}> 
                      {loadWidget ?
                        <>
                        {getLabel("caricamento")}
                        </>
                        :
                        !loadWidget && pageWidget ? getLabel(pageWidget) 
                        :
                        getLabel("nessun_widget")
                      }
                    </Button>
                    <Input className='hide'/>
                  </Form.Item>
                <ModalForm open={open} onCancel={hideUserModal} />
                {/*select per individuare la sottoapp da cui prendere i dati*/}
                <Form.Item
                  name='page_franchise_link'
                  label={getLabel("contenuti_app")}
                  tooltip={getLabel("tooltip_contenuti_app")}
                >
                  <Select
                      showSearch
                      placeholder={getLabel("cerca_seleziona")}
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: 'jack',
                          label: 'Jack',
                        },
                        {
                          value: 'lucy',
                          label: 'Lucy',
                        },
                        {
                          value: 'tom',
                          label: 'Tom',
                        },
                      ]}
                    />
                </Form.Item>
                {/*url a cui la pagina punta*/}
                <Form.Item 
                  name='url' 
                  label="Url"
                >
                  <Input placeholder="https://www.miourl.it"/>
                </Form.Item>
                {/*posizione della pagina*/}
                <Form.Item 
                  name='position' 
                  label={getLabel("position")}
                  initialValue={0}
                >
                  <InputNumber min={0}/>
                </Form.Item>

                <div className='w-100 px-10 fs-18 fw-5'>
                  {getLabel("visualizzazione")}
                  <Divider/>
                </div>

                {/*regole di visibilità della menu*/}
                <Row>
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:6}}
                    xxl={{span:4}}
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>{getLabel("visibile")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:18}}
                    xxl={{span:20}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        <Form.Item
                            name='in_home'
                            className='mb-0'
                            valuePropName="checked"
                          >
                            <Checkbox>In Home</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{span:24}}
                        sm={{span:12}}
                        lg={{span:7}}
                        xxl={{span:5}}
                        align="start"
                      >
                        <Form.Item
                          name='in_menu'
                          valuePropName="checked"
                        >
                          <Checkbox>In Menu</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                 
                {/*regole di visibilità per utenti*/}

                <Row>
                  <Col
                    xs={{span:8}}
                    sm={{span:8}}
                    lg={{span:6}}
                    xxl={{span:4}}
                  >
                    <Row align={{sm:"end", xs:"start"}}>
                      <div className='ant-form-item-label ea-label'>
                        <label>{getLabel("visibile") + " " + getLabel("per")}</label>
                      </div>
                    </Row>
                  </Col>
                  
                  <Col
                    xs={{span:24}}
                    sm={{span:16}}
                    lg={{span:18}}
                    xxl={{span:20}}
                   >
                    <Row>
                      <Col 
                       xs={{span:24}}
                       sm={{span:8}}
                       lg={{span:6}}
                       xl={{span:5}}
                       xxl={{span:3}}
                      >
                        <Form.Item
                            name='logged'
                            className='mb-0'
                            valuePropName="checked"
                          >
                            <Checkbox>{getLabel("logged")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col 
                       xs={{span:24}}
                       sm={{span:13}}
                       lg={{span:10}}
                       xl={{span:10}}
                       xxl={{span:5}}
                       align="start"
                      >
                        <Form.Item
                          name='not_logged'
                          valuePropName="checked"
                        >
                          <Checkbox>{getLabel("not_logged")}</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/*abilitazione della pagina*/}

                <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#7ace4c"
                      },
                    }}
                  >
                  <Form.Item
                    name='enabled'
                    valuePropName="checked"
                    label={getLabel("status")}
                  >
                    <Checkbox>{getLabel("enabled")}</Checkbox>
                  </Form.Item>
                </ConfigProvider>
                {/*salva o salva e rimani*/}
                <Form.Item 
                  label=" " 
                  className='save-form'
                >
                  <div className='flex gap-10 flex-wrap'>
                    <Button type="primary" size="medium" htmlType="submit">
                    {getLabel("salva")}
                    </Button>
                    <Button type="primary" size="medium" className="bg-secondary" htmlType="submit">
                    {getLabel("salva_rimani")}
                    </Button>
                  </div>
                </Form.Item>

              </Form>
            :
              <div className='w-100 flex justify-center'>
                <Spin/>
              </div>
            }
          </Col>
        </Row>
      </div>   
    </DashboardLayout>
  );
}

export default ThemeEditPage;