import React from 'react';

function LogoExtended(props) {
  return (
    <img
      className='ea-logo-extended'
      src='/ea-logo-extended-colored.svg'
    />
  );
}

export default LogoExtended;