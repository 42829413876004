import { ApiCallPost } from "../Backoffice";

const AppById = async (app_id, token_user) => {
  
  const body={
    token_user,
    app_id
  };
    const res = await  ApiCallPost("app_by_id", JSON.stringify(body));
    if(res.response === "ok"){
      return res.results;
    }else{
      console.log(res.reasons)
    }

};

export {AppById};