import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { DownOutlined, CopyOutlined, DeleteOutlined, EditOutlined, QrcodeOutlined, CheckOutlined, CloseOutlined, CheckCircleFilled, CloseCircleFilled, PlusOutlined } from '@ant-design/icons';

import { Button, ConfigProvider, Dropdown, Space, Table, Tooltip } from 'antd';

import { getLabel } from '../../../../helpers/Translations';
import { UserData } from '../../../../helpers/UserData';
import TableActions from '../../../tables/TableActions';
import DashboardLayout from '../../../DashboardLayout';
import { AppById } from '../../../../helpers/AppById';
import { ApiCallPost } from '../../../../Backoffice';
import Breadcrumbs from '../../../Breadcrumbs';


const queryParams = window.location.search;



function ThemePagesCategories(props) {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [endpoint, setEndpoint] = useState("pages_groups");
  const [groupPages, setGroupPages] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [appToken, setAppToken] = useState("");
  const [pageList, setPageList] = useState([]);
  const [appName, setAppName] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [filter, setFilter] = useState([]); //i filtri sono da risettare quando arrivano i dati dinamici, quindi sono in uno useeffect
  const [load, setLoad] = useState(false);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const appId = params.id;




  // -------------- gestione dei colori --------------
  const [buttonColor, setButtonColor] = useState("");

  // -------------- definisco le colonne della tabella con anche il set di impostazioni/chiamate nei riordini  -------------- 
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      responsive: ['sm','md','lg', 'xl'],
      sorter: (a,b) => {
        setSort(sort == "asc" ? "desc" : "asc");
        setOrderBy("id");
      },
    },
    {
      title: getLabel("nome"),
      dataIndex: 'name',
    },
    {
      title: getLabel("enabled"),
      dataIndex: 'enabled',
      responsive: ['sm','md','lg', 'xl'],
    },
    {
      title: 'Azioni',
      render: (_,record) => (
        <TableActions url={`/apps_pages_categories/${record.id}/edit`}/>
      ),
    },
  ];

  //  -------------- con l'id app dell'url recupero nome e token e li setto  -------------- 
  useEffect(()=>{
    (async()=> {
      const res = await AppById(appId, UserData.accessToken);
      if(res){
        setAppName(res[0].name);
        setAppToken(res[0].token_key);
      };
    })();
  },[])

  //  --------------  quando arrivano i dati delle briciole le setto  -------------- 
  useEffect(()=>{
    setBreadcrumbs([
      {
        name:appName,
        url:"/"+UserData.appId
      },
      {
        name:getLabel("apps_pages"),
        url:"/apps_pages/"+UserData.appId
      },
      {
        name:getLabel("gruppi_pagine")
      },
    ])
  },[appName])

  //  --------------  chiamata per ricevere i dati gruppi di pagine  -------------- 
  useEffect(()=>{

    if(!appToken) return;

    setLoad(true);
    
    const body = {
      token_user:UserData.accessToken,
      order_by:orderBy && orderBy,
      token_key:appToken,
			take:rowsPerPage,
      pagination:page,
      order_type:sort,
    };
    (async()=>{
      const res = await  ApiCallPost(endpoint + queryParams, JSON.stringify(body));
     
      if(res === "token_user_not_present"){
        console.log(res);
      }if(res === "required_fields_missing"){
        console.log(res);
      }else{

        setTotalRows(res.results_total_rows);

        if(res.results){
          res.results.forEach(e => {
            e["key"] = e.id;
          }); //aggiungo una key alle informazioni per evitare errori in react
        }
        setPageList(res.results)
        setLoad(false);
      }
    })()

  },[appToken, UserData.accessToken, rowsPerPage, page, sort])

 // --------------  selezionare quanti risultati per pagina  -------------- 
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize)
  };
  // --------------  cambio della pagina  -------------- 
  const onPageChange = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  return (
    <DashboardLayout>
      <Breadcrumbs  navigation={breadcrumbs}/>
      <div className='ea-card mt-20'>
        <div className='flex w-100 space-between py-20'>
          <div className='fs-18 fw-5'>{getLabel("gruppi_pagine")}</div>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#7ace4c"
              },
            }}
          >
            <Button type='primary' onClick={() => window.open("/apps_pages_categories/"+appId+"/create", "_self")}> 
              <Space>
                Nuovo
                <PlusOutlined />
              </Space>
            </Button>
          </ConfigProvider>
        </div>
          <Table
            columns={columns}
            dataSource={pageList}
            loading={load}
            pagination={{
              onShowSizeChange:onShowSizeChange,
              pageSizeOptions:[15,30,50,100],
              defaultCurrent:page + 1,
              position: ["topRight", "bottomRight"],
              onChange:onPageChange,
              pageSize:rowsPerPage,
              current:page + 1,
              total:totalRows,
            }}
          />
      </div>
    </DashboardLayout>
  );
}

export default ThemePagesCategories;

