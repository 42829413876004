import React from 'react';

import { CopyOutlined, DeleteOutlined, EditOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Tooltip } from 'antd';

function TableActions({url}) {

  const navigate = useNavigate();

  // ---------------- funzione per generare il qr code -------------------
  const generateQrCode = () => {
    console.log("genera qr code")
  }
  // ---------------- funzione per generare il qr code -------------------
  const deleteData = () => {
    console.log("elimina un dato")
  }
  // ---------------- funzione per generare il qr code -------------------
  const duplicateData = () => {
    console.log("duplica un dato")
  }

  return (
    <div className='flex gap-15 fs-18'>
      <Tooltip title="Visualizza QrCode">
        <QrcodeOutlined onClick={generateQrCode}/>
      </Tooltip>
      <Tooltip title="Modifica" color="#41b3f9">
        <EditOutlined onClick={()=> navigate(url)} className="color-blue"/>
      </Tooltip>
      <Tooltip title="Duplica" color="#ffbb44">
        <CopyOutlined onClick={duplicateData} className="color-yellow"/>
      </Tooltip>
      <Tooltip title="Elimina" color="#f33155">
        <DeleteOutlined onClick={deleteData} className="color-red"/>
      </Tooltip>
    </div>
  );
}

export default TableActions;